import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { Button, ButtonType, Checkbox, Heading, Icon, List, ListItem, Size, Tooltip, Placement } from '@vaisala-rockhopper/components';
import './ConfiguratorSelects.scss';
import ConfiguratorSelectComponent from '../inputs/ConfiguratorSelectComponent';
import { filterProbes, ProbeProps, ProductConfiguration, TransmitterProps } from '../configuration/ConfigurationFunctions';
import { VaiIcon } from '@vaisala-rockhopper/design-tokens';
import ConfiguratorUtilSelectComponent from '../inputs/ConfiguratorUtilSelectComponent';
import i18next from 'i18next';
import ConfiguratorTransmitterSelectComponent from '../inputs/ConfiguratorTransmitterSelectComponent';
import ConfiguratorSelectDrawer from '../inputs/ConfiguratorSelectDrawer';
import CTAButton from '../navigation/CTAButton';
import { ModalType } from '../modals/Modals';
import { OptionsConfig } from '../modals/OptionsModal';
interface SelectedProductOptionsProps {
  selectedProbes?: Array<ProbeProps>;
  selectedTransmitter?: TransmitterProps;
}
interface ConfiguratorSelectsProps {
  filteredProbes: Array<ProbeProps>;
  filteredSecondaryProbes: Array<ProbeProps>;
  tempCompProbe: Array<ProbeProps>;
  filteredTransmitters: Array<TransmitterProps>;
  selectedMeasurements: string[];
  onConfiguratorSelectChange(value: string, position: string): void;
  selectedProductOptions?: SelectedProductOptionsProps;
  selectedProductSelections: ProductConfiguration;
  selectedTransmitter: TransmitterProps;
  onTransmitterChange: (arg0: TransmitterProps) => void;
  onSelectedTransmitterOptionsChange(select_key: string, selected: string): void;
  selectedTransmitterOptions: { display: string; power_option: string; probe_connection_cable: string };
  transmitterType?: string;
  openModal: (type: ModalType, title?: string, optionsConfig?: OptionsConfig, onAccept?: VoidFunction, onReject?: VoidFunction) => void;
  onHoveredCableChange(value: string): void
}

const ConfiguratorSelects = ({ ...props }: ConfiguratorSelectsProps) => {
  const [hasSecondProbe, setHasSecondProbe] = React.useState(props.selectedProductSelections.probe2.active);
  const [selectedComponents, setSelectedComponents] = React.useState(() => {
    const initialValue = props.selectedProductSelections;
    return initialValue || [];
  });
  const [temperatureCompensationSelected, setTemperatureCompensationSelected] = React.useState(
    props.selectedProductSelections.probe1.temperature_compensation || false
  );
  const [filteredProbes, setFilteredProbes] = React.useState(props.filteredProbes);
  const [filteredSecondaryProbes, setFilteredSecondaryProbes] = React.useState(props.filteredSecondaryProbes);
  const [filteredTransmitters, setFilteredTransmitters] = React.useState(props.filteredTransmitters);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState<Array<ProbeProps | TransmitterProps>>([]);
  const [drawerType, setDrawerType] = React.useState<string>('');

  const onDrawerDataChange = (optionSelected: string) => {
    switch (optionSelected) {
      case 'probe_1':
        setDrawerData([...filteredProbes]);
        setDrawerType(optionSelected);
        break;
      case 'probe_2':
        if (filteredSecondaryProbes?.length > 0) {
          setDrawerData([...filteredSecondaryProbes]);
        } else {
          setDrawerData([...filteredProbes]);
        }
        setDrawerType(optionSelected);
        break;
      case 'transmitter':
        setDrawerData([...filteredTransmitters]);
        setDrawerType(optionSelected);
        break;
      default:
        setDrawerData([]);
        setDrawerType('');
        break;
    }
  };

  React.useEffect(() => {
    setFilteredProbes(props.filteredProbes);
  }, [props.filteredProbes]);

  React.useEffect(() => {
    setFilteredSecondaryProbes(props.filteredSecondaryProbes);
  }, [props.filteredSecondaryProbes]);

  React.useEffect(() => {
    setFilteredTransmitters(props.filteredTransmitters);
  }, [props.filteredTransmitters]);

  React.useEffect(() => {
    setSelectedComponents(props.selectedProductSelections);
  }, [props.selectedProductSelections]);

  React.useEffect(() => {
    setHasSecondProbe(props.selectedProductSelections.probe2.active);
  }, [props.selectedProductSelections.probe2.active]);

  const { t } = useTranslation();

  const toggleProbe = () => {
    if (hasSecondProbe) {
      props.onConfiguratorSelectChange('remove', 'probe2');
      setHasSecondProbe(false);
    } else {
      if (props.transmitterType === 'fixed') {
        props.onConfiguratorSelectChange('Indigo520', 'transmitter');
      }
      setHasSecondProbe(true);
    }
  };

  React.useEffect(() => {
    // if (props.selectedProductSelections.probe2.active) {
    //   props.onConfiguratorSelectChange('Indigo520', 'transmitter');
    // }
    // setHasSecondProbe(props.selectedProductSelections.probe2.active)
  }, [props.selectedProductSelections.probe2.active]);

  React.useEffect(() => {
    if (filteredTransmitters.length === 1) {
      props.onConfiguratorSelectChange(filteredTransmitters[0].value, 'transmitter');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTransmitters]);

  React.useEffect(() => {
    if (props.selectedProductSelections.probe1.selected !== 'HMP7') {
      setTemperatureCompensationSelected(false);
    }
  }, [props.selectedProductSelections.probe1]);

  React.useEffect(() => {
    if (props.selectedProductSelections.probe1.selected === 'HMP7') {
      if (temperatureCompensationSelected) {
        if (props.transmitterType === 'fixed') {
          props.onConfiguratorSelectChange('Indigo520', 'transmitter');
        }
        setHasSecondProbe(true);
        props.onConfiguratorSelectChange('TMP1', 'temp_compensation');
      } else {
        setHasSecondProbe(false);
        props.onConfiguratorSelectChange('remove', 'temp_compensation');
      }
    }
  }, [temperatureCompensationSelected]);

  const SecondProbe = () => {
    // if (selectedComponents.transmitter.selected === "Indigo520") {
    // if (hasSecondProbe) {
    if (temperatureCompensationSelected) {
      return (
        <>
          <ConfiguratorSelectComponent
            key={'probe2'}
            select_key={'probe2'}
            heading={t('ui.probe_2')}
            options={props.tempCompProbe}
            variant="dark"
            width={Size.L}
            onConfiguratorSelectChange={props.onConfiguratorSelectChange}
            selected={'TMP1'}
            onToggleProbe={toggleProbe}
            isActive={props.selectedProductSelections.probe2.active ?? false}
            hasSecondProbe={hasSecondProbe}
            setIsDrawerOpen={setIsDrawerOpen}
            onDrawerDataChange={onDrawerDataChange}
            textData={props.selectedProductSelections?.probe2?.imageData?.infobox ?? false}
          />
          {selectedComponents.probe2.imageData?.probe_body && (
            <ConfiguratorUtilSelectComponent
              key={'probe2_cable'}
              select_key={'probe2_cable'}
              label={t('ui.probe_cable')}
              options={selectedComponents.probe2.imageData.cables.internal || []}
              variant="dark"
              width={Size.L}
              selected={props.selectedProductSelections.probe2.probe_cable}
              onConfiguratorSelectChange={props.onConfiguratorSelectChange}
              onHoveredCableChange={props.onHoveredCableChange}
            />
          )}
        </>
        // <></>
      );
    } else {
      if (filteredSecondaryProbes.length > 0) {
        return (
          <>
            <ConfiguratorSelectComponent
              key={'probe2'}
              select_key={'probe2'}
              heading={t('ui.probe_2')}
              options={filteredSecondaryProbes}
              variant="dark"
              width={Size.L}
              onConfiguratorSelectChange={props.onConfiguratorSelectChange}
              selected={selectedComponents.probe2.selected}
              onToggleProbe={toggleProbe}
              isActive={props.selectedProductSelections.probe2.active ?? false}
              hasSecondProbe={hasSecondProbe}
              setIsDrawerOpen={setIsDrawerOpen}
              onDrawerDataChange={onDrawerDataChange}
              textData={props.selectedProductSelections?.probe2?.imageData?.infobox ?? false}
            />
            {selectedComponents.probe2.imageData?.probe_body && (
              <ConfiguratorUtilSelectComponent
                key={'probe2_cable'}
                select_key={'probe2_cable'}
                label={t('ui.probe_cable')}
                options={selectedComponents.probe2.imageData.cables.internal || []}
                variant="dark"
                width={Size.L}
                selected={props.selectedProductSelections.probe2.probe_cable}
                onConfiguratorSelectChange={props.onConfiguratorSelectChange}
                onHoveredCableChange={props.onHoveredCableChange}
              />
            )}
          </>
          // <></>
        );
      } else {
        return (
          <>
            <ConfiguratorSelectComponent
              key={'probe2'}
              select_key={'probe2'}
              heading={t('ui.probe_2')}
              options={filteredProbes}
              variant="dark"
              width={Size.L}
              onConfiguratorSelectChange={props.onConfiguratorSelectChange}
              selected={selectedComponents.probe2.selected}
              onToggleProbe={toggleProbe}
              isActive={props.selectedProductSelections.probe2.active ?? false}
              hasSecondProbe={hasSecondProbe}
              setIsDrawerOpen={setIsDrawerOpen}
              onDrawerDataChange={onDrawerDataChange}
              textData={props.selectedProductSelections?.probe2?.imageData?.infobox ?? false}
            />
            {selectedComponents.probe2.imageData?.probe_body && (
              <ConfiguratorUtilSelectComponent
                key={'probe2_cable'}
                select_key={'probe2_cable'}
                label={t('ui.probe_cable')}
                options={selectedComponents.probe2.imageData.cables.internal || []}
                variant="dark"
                width={Size.L}
                selected={props.selectedProductSelections.probe2.probe_cable}
                onConfiguratorSelectChange={props.onConfiguratorSelectChange}
                onHoveredCableChange={props.onHoveredCableChange}
              />
            )}
          </>
          // <></>
        );
      }
    }
    // } else {
    //     return (
    //         <Button
    //             buttonType={ButtonType.Link}
    //             onClick={() => toggleProbe()}
    //             startIcon={<Icon name={VaiIcon.Plus}/>}
    //         >{t('ui.add_probe')}</Button>
    //     );
    // }
    // } else {
    //     return <></>
    // }
  };

  const TransmitterOptions = () => {
    if (selectedComponents.transmitter.imageData) {
      if (selectedComponents.transmitter.imageData.images.no_display === undefined) {
        return (
          <>
            {/* <div className="configurator-select-component-wrapper">
              <List>
                <ListItem>{t('ui.display')}</ListItem>
              </List>
            </div> */}
            <ProbeConnectionCableOptions />
            <PowerOptions />
          </>
        );
      } else {
        return (
          <>
            <ConfiguratorSelectComponent
              key={'display'}
              select_key={'display'}
              heading={''}
              options={[
                { value: '1', label: t('ui.display') },
                { value: '0', label: t('ui.no_display') },
              ]}
              variant="dark"
              width={Size.L}
              onTransmitterOptionChange={props.onSelectedTransmitterOptionsChange}
              selected={props.selectedTransmitterOptions.display}
              setIsDrawerOpen={setIsDrawerOpen}
              onDrawerDataChange={onDrawerDataChange}
            />
            <ProbeConnectionCableOptions />
            <PowerOptions />
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const ProbeConnectionCableOptions = () => {
    let cableLengths = selectedComponents.transmitter.imageData.probe_connection_cables;
    if (
      cableLengths.includes('0') &&
      !props.selectedProductSelections.probe1.imageData.without_cable_to_transmitter &&
      !props.selectedProductSelections.probe1.probe_body
    ) {
      cableLengths = cableLengths.filter((val: any) => val !== '0');
    }
    return (
      <ConfiguratorUtilSelectComponent
        key={'probe_connection_cable'}
        select_key={'probe_connection_cable'}
        heading={''}
        // label={t('ui.probe_connection_cable') + ": "}
        label={hasSecondProbe ? t('ui.transmitter_cables') : t('ui.transmitter_cable')}
        options={cableLengths}
        variant="dark"
        width={Size.L}
        onTransmitterOptionChange={props.onSelectedTransmitterOptionsChange}
        selected={props.selectedTransmitterOptions.probe_connection_cable}
        onHoveredCableChange={props.onHoveredCableChange}
      />
    );
  };

  const PowerOptions = () => {
    if (
      selectedComponents.transmitter.selected !== undefined &&
      selectedComponents.transmitter.selected !== null &&
      selectedComponents.transmitter.selected !== ''
    ) {
      if (selectedComponents.transmitter.imageData.operating_powers !== undefined) {
        if (selectedComponents.transmitter.imageData.operating_powers.length > 1) {
          return (
            <>
              <ConfiguratorSelectComponent
                key={'power_option'}
                select_key={'power_option'}
                heading={''}
                label={t('ui.power') + ': '}
                options={selectedComponents.transmitter.imageData.operating_powers}
                variant="dark"
                width={Size.L}
                onTransmitterOptionChange={props.onSelectedTransmitterOptionsChange}
                selected={props.selectedTransmitterOptions.power_option}
                setIsDrawerOpen={setIsDrawerOpen}
                onDrawerDataChange={onDrawerDataChange}
              />
              <div className="configurator-select-component-wrapper">
                <List>
                  <ListItem>
                    {t('ui.supply')}:{' '}
                    {selectedComponents.transmitter.imageData.operating_powers?.find(
                      (obj: any) => obj.value === props.selectedTransmitterOptions.power_option
                    )?.supply ?? ''}
                  </ListItem>
                </List>
              </div>
            </>
          );
        } else if (selectedComponents.transmitter.imageData.operating_powers.length < 1) {
          return <></>;
        } else {
          return (
            <div className="configurator-select-component-wrapper">
              <List>
                <ListItem>
                  {t('ui.power')}: {selectedComponents.transmitter.imageData.operating_powers[0].label}
                </ListItem>
                <ListItem>
                  {t('ui.supply')}: {selectedComponents.transmitter.imageData.operating_powers[0].supply}
                </ListItem>
              </List>
            </div>
          );
        }
      } else {
        return (
          <div className="configurator-select-component-wrapper">
            <List>
              {/* <ListItem>{t('ui.power')}: {props.selectedTransmitter.operating_powers[0].label}</ListItem>
                            <ListItem>{t('ui.supply')}: {props.selectedTransmitter.operating_powers[0].supply}</ListItem> */}
            </List>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  const ConnectionType = (props: any) => {
    const val = props.data;
    return (
      <>
        <ListItem style={{ fontWeight: 600, marginTop: '1rem' }} key={val.output[i18next.language]}>
          {val.output[i18next.language][0].toUpperCase() + val.output[i18next.language].slice(1)}
        </ListItem>
        <ListItem>{val.type}</ListItem>
        {val.channels !== undefined && (
          <ListItem key={'connection_channels'}>
            {t('ui.channels')}: {val.channels}
          </ListItem>
        )}
        {val.relays !== undefined && (
          <ListItem key={'connection_relays'}>
            {t('ui.relays')}: {val.relays}
          </ListItem>
        )}
      </>
    );
  };

  const AvailableConnections = () => {
    if (
      props.selectedProductSelections.transmitter.active &&
      props.selectedTransmitterOptions.power_option !== '' &&
      props.transmitterType !== 'handheld'
    ) {
      if (selectedComponents.transmitter.imageData !== undefined) {
        const connection_data = selectedComponents.transmitter.imageData.operating_powers?.find(
          (obj: any) => obj.value === props.selectedTransmitterOptions.power_option
        );
        return (
          <div className="configurator-select-component-wrapper">
            <Heading level={3} style={{ marginBottom: '-0.5rem'}} >{t('ui.connections')}</Heading>
            <List>
              {connection_data?.connections?.map((val: any, i: number) => (
                <ConnectionType key={i} data={val} />
              ))}
            </List>
          </div>
        );
      } else {
        return <></>;
      }
    } else if (!props.selectedProductSelections.transmitter.active && props.selectedProductSelections.probe1.selected !== '') {
      const connection_data = props.selectedProductSelections.probe1.imageData.operating_powers[0];
      return (
        <div className="configurator-select-component-wrapper">
          <Heading level={3}>{t('ui.connections')}</Heading>
          <List>
            {connection_data?.connections?.map((val: any, i: number) => (
              <ConnectionType key={i} data={val} />
            ))}
          </List>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="configurator-select-wrapper">
      <div className={`configurator-selects ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
        <Heading level={2} className="vai-margin-top-none vai-margin-bottom-none">3. {t('ui.selections.heading')}</Heading>
        <ConfiguratorSelectComponent
          key={'probe1'}
          select_key={'probe1'}
          heading={t('ui.probe_1')}
          options={filteredProbes}
          variant="dark"
          width={Size.L}
          onConfiguratorSelectChange={props.onConfiguratorSelectChange}
          selected={selectedComponents.probe1.selected}
          setIsDrawerOpen={setIsDrawerOpen}
          onDrawerDataChange={onDrawerDataChange}
          textData={props.selectedProductSelections?.probe1?.imageData?.infobox ?? false}
        />
        {selectedComponents.probe1.probe_body && (
          <ConfiguratorUtilSelectComponent
            key={'probe1_cable'}
            select_key={'probe1_cable'}
            label={t('ui.probe_cable')}
            options={selectedComponents.probe1.imageData.cables.internal || []}
            variant="dark"
            width={Size.L}
            selected={props.selectedProductSelections.probe1.probe_cable}
            onConfiguratorSelectChange={props.onConfiguratorSelectChange}
            onHoveredCableChange={props.onHoveredCableChange}
          />
        )}
        {selectedComponents.probe1.selected === 'HMP7' && (
          <div className="configurator-select-component-wrapper temp-comp">
            <Checkbox
              checked={temperatureCompensationSelected}
              label={t('ui.transmitter.temp_compensation')}
              onChange={() => setTemperatureCompensationSelected(!temperatureCompensationSelected)}
            />
            <Tooltip
              arrow={true}
              placement={Placement.BottomStart}
              triggerElement={
                <Button buttonType={ButtonType.Link} className="info-button tooltip" buttonSize={Size.M} startIcon={<Icon name={VaiIcon.HelpO} />} />
              }
            >
              {t('ui.tooltip.temp_comp')}
          </Tooltip>
          </div>
        )}
        {props.selectedTransmitter && <SecondProbe />}
        {filteredTransmitters.length > 1 ? (
          <ConfiguratorTransmitterSelectComponent
            key={'transmitter'}
            select_key={'transmitter'}
            heading={t('ui.transmitter')}
            options={filteredTransmitters}
            variant="dark"
            width={Size.L}
            onConfiguratorSelectChange={props.onConfiguratorSelectChange}
            selected={selectedComponents.transmitter.selected}
            isActive={props.selectedProductSelections.transmitter.active}
            setIsDrawerOpen={setIsDrawerOpen}
            onDrawerDataChange={onDrawerDataChange}
            textData={props.selectedProductSelections?.transmitter?.imageData?.infobox ?? false}
          />
        ) : (
          <div className="configurator-select-component-wrapper">
            <Heading level={3}>
              {t('ui.transmitter')}
              <Tooltip
                arrow={true}
                placement={Placement.BottomStart}
                triggerElement={
                  <Button
                    buttonType={ButtonType.Link}
                    className="info-button tooltip"
                    buttonSize={Size.M}
                    startIcon={<Icon name={VaiIcon.HelpO} />}
                  />
                }
              >
                {t('ui.tooltip.select_transmitter')}
              </Tooltip>
            </Heading>
            <List>
              <ListItem>{filteredTransmitters[0].label[i18next.language]}</ListItem>
            </List>
          </div>
        )}
        {filteredTransmitters.length > 0 && props.selectedProductSelections.transmitter.active && selectedComponents.transmitter.selected !== '' && (
          <TransmitterOptions />
        )}
        <AvailableConnections />
      </div>
      <div className="buttons">
        {/* <CTAButton onButtonClick={() => props.openModal(`modal.share`)} color={'aqua-medium'}>
          {t('ui.share')}
        </CTAButton> */}
        <CTAButton onButtonClick={() => props.openModal(`modal.contact`)} color={'orange-dark'}>
          {t('ui.share')}
        </CTAButton>
      </div>
      <ConfiguratorSelectDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        drawerData={drawerData}
        selectedProductSelections={props.selectedProductSelections}
        drawerType={drawerType}
        onConfiguratorSelectChange={props.onConfiguratorSelectChange}
      />
    </div>
  );
};

export default ConfiguratorSelects;
