import Konva from 'konva';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Image as KonvaImage } from 'react-konva';
import { Infobox, ProbeProps, ProductConfiguration, TransmitterProps } from '../../configuration/ConfigurationFunctions';
import i18next from 'i18next';

type Image = {
  id: number;
  src: { [key: string]: any };
  anchors: Array<number[]>;
  probe_body: string;
  m12_connector: boolean;
  position: string;
  label: string;
  infobox: Infobox;
  without_cable_to_transmitter: boolean;
  mirrored?: boolean;
};

type ImageOnCanvas = {
  img: Image;
  naturalWidth: number;
  naturalHeight: number;
};

type Props = {
  stageWidth: number;
  stageHeight: number;
  image: Image;
  images: Array<Image>;
  onCenterAnchorChange: (arg0: any) => void;
  centerAnchor: any;
  onImageDrawn: (imageWidth: number, imagePos: string, newImage: ImageOnCanvas) => void;
  imageWidths: any;
  selectedTransmitter?: TransmitterProps | undefined;
  onCanvasScaleChanged: (newScale: number) => void;
  canvasScale: number;
  selectedTransmitterOptions: { display: string; power_option: string; probe_connection_cable: string; barometric_pressure: string };
  hasSecondProbe: boolean;
  probeAttachments: Array<ProbeProps>;
  productSelections: ProductConfiguration;
  probeConnectedToTransmitter: boolean;
  onShowToolTip?: (toolTipPos: number[], imageInfo: Image | null, hover: boolean) => void;
  hoveringElement: boolean;
  transmitterType?: string;
};

const CanvasImage = (props: Props) => {
  let img = document.createElement('img');

  const checkLocation = (imgSource: string) => {
    const location = window.location.hostname
    const protocol = window.location.protocol
    const urlPattern = /^https?:\/\/\S+/i
    let url: URL;

    if (urlPattern.test(imgSource)) {
      url = new URL(imgSource)
    } else {
      url = new URL(protocol + '//' + location + imgSource)
    }

    switch (location) {
      case 'indigo-selector.vaisala.com':
        imgSource = protocol + '//indigo-selector.vaisala.com' + url.pathname;
        break;
      case 'd2w7vks24hsr8b.cloudfront.net':
        imgSource = protocol + '//d2w7vks24hsr8b.cloudfront.net' + url.pathname;
        break;
      case 'vaisaladev.prod.acquia-sites.com':
      case 'vaisala.prod.acquia-sites.com':
      case 'vaisala.com':
      case 'www.vaisala.com':
        imgSource = protocol + '//indigo-selector.vaisala.com' + url.pathname;
        break;
      default:
        break;
    }
    return imgSource
  }

  let connectorImg = document.createElement('img');
  connectorImg.src = checkLocation('/product-images/M12-connector.gif');

  const [image, setImage] = useState(img);
  const [connectorImage] = useState(connectorImg);
  const [imagePos, setImagePos] = useState('');
  const [totalWidth, setTotalWidth] = useState(0);
  const [imageData, setImageData] = useState(props.image);

  useEffect(() => {
    setImageData(props.image);
  }, [props.image]);

  const IMAGE_PADDING = 100;

  const [hovered, setHovered] = useState(true);

  const getPosition = (type: string) => {
    switch (type) {
      case 'left':
        return 'probe1';
      case 'center':
        return 'transmitter';
      case 'right':
        return 'probe2';
      default:
        return 'right';
    }
  };

  // React.useEffect(() => {
  //   if (!props.hoveringElement) {
  //     setHovered(false);
  //     props.onShowToolTip([0, 0], null, false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.hoveringElement]);

  useEffect(() => {
    let img = document.createElement('img');
    img.src = '';
    if (imageData.src.default !== undefined) {
      img.src = checkLocation(imageData.src.default)
    } else {
      if (props.hasSecondProbe) {
        if (props.selectedTransmitterOptions.barometric_pressure === '1') {
          if (props.selectedTransmitterOptions.display === '1') {
            img.src = imageData.src.barometric_2;
          } else {
            img.src = imageData.src.barometric_2_no_display;
          }
        } else {
          if (props.selectedTransmitterOptions.display === '1') {
            img.src = imageData.src.display_2 ?? imageData.src.display;
          } else {
            img.src = imageData.src.no_display_2;
          }
        }
      } else {
        if (props.selectedTransmitterOptions.barometric_pressure === '1') {
          if (props.selectedTransmitterOptions.display === '1') {
            img.src = imageData.src.barometric;
          } else {
            img.src = imageData.src.barometric_no_display;
          }
        } else {
          if (props.selectedTransmitterOptions.display === '1') {
            img.src = imageData.src.display;
          } else {
            img.src = imageData.src.no_display;
          }
        }
      }
      img.src = checkLocation(img.src)
    }

    img.onload = () => {
      if (imageData.position !== 'left_probe_body' && imageData.position !== 'right_probe_body') {
        if (props.productSelections[getPosition(imageData.position)].active) {
          img.width = img.naturalWidth;
          img.height = img.naturalHeight;
          setImagePos(imageData.position);

          setImage(img);
          props.onImageDrawn(img.width, imageData.position, { img: imageData, naturalWidth: img.naturalWidth, naturalHeight: img.naturalHeight });
        }
      } else {
        if (imageData.position !== 'right_probe_body' && props.productSelections['probe1'].probe_body) {
          img.width = img.naturalWidth;
          img.height = img.naturalHeight;
          setImagePos(imageData.position);
          setImage(img);
          props.onImageDrawn(img.width, imageData.position, { img: imageData, naturalWidth: img.naturalWidth, naturalHeight: img.naturalHeight });
        } else {
          if (props.productSelections['probe2'].probe_body) {
            img.width = img.naturalWidth;
            img.height = img.naturalHeight;
            setImagePos(imageData.position);
            setImage(img);
            props.onImageDrawn(img.width, imageData.position, {
              img: imageData,
              naturalWidth: img.naturalWidth,
              naturalHeight: img.naturalHeight,
            });
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData, props.canvasScale, props.selectedTransmitterOptions, props.hasSecondProbe]);

  useEffect(() => {
    // let currentTotalWidth = calculateTotalWidth(imageDataWidths);
    let paddingsToAccountFor = Object.keys(props.imageWidths).filter((obj) => props.imageWidths[obj as keyof typeof props.imageWidths] > 0).length;
    let currentTotalWidth = calculateTotalWidth(props.imageWidths) + IMAGE_PADDING * paddingsToAccountFor * props.canvasScale;
    const imageInfo = imageData;
    setTotalWidth(currentTotalWidth);
    if (imageData.position === 'center') {
      let paddingWidth = getPaddingWidth('center');
      let newCenterAnchor: any = [];
      imageInfo.anchors.forEach((anchor: number[], key: number) => {
        // let anchorPosX = 0;
        let anchorPosX =
          (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2 +
          paddingWidth.padding * props.canvasScale +
          IMAGE_PADDING * paddingWidth.widths.length * props.canvasScale +
          anchor[0] * (image!.naturalWidth * props.canvasScale);
        // let anchorPosX = (((props.stageWidth - currentTotalWidth) / 2  + IMAGE_PADDING) + ((props.imageWidths.left * props.canvasScale) + IMAGE_PADDING) + (anchor[0]*(image!.naturalWidth * props.canvasScale)));
        let anchorPosY =
          props.stageHeight / 2 + anchor[1] * (image!.naturalHeight * props.canvasScale);
        if (props.probeConnectedToTransmitter) {
          anchorPosY -= (image.height * props.canvasScale) / 2;
        }
        newCenterAnchor = { ...newCenterAnchor, [key]: [anchorPosX, anchorPosY] };
      });
      props.onCenterAnchorChange(newCenterAnchor);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imageWidths]);

  const calculateTotalWidth = (totalW: any) => {
    const arr = Object.values(totalW);
    let sum = 0;
    arr.forEach((value: any) => {
      if (value > 0) {
        sum += value * props.canvasScale;
      }
    });
    return sum;
  };

  const getPaddingWidth = (imagePos: string) => {
    let paddingWidth = {
      widths: [0],
      padding: 0,
    };
    switch (imagePos) {
      case 'left':
        break;
      case 'left_probe_body':
        paddingWidth.widths = Object.keys(props.imageWidths)
          .filter((obj) => props.imageWidths[obj] > 0)
          .filter((obj) => obj === 'left')
          .map((obj) => props.imageWidths[obj]);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      case 'center':
        paddingWidth.widths = Object.keys(props.imageWidths)
          .filter((obj) => props.imageWidths[obj] > 0)
          .filter((obj) => obj === 'left' || obj === 'left_probe_body')
          .map((obj) => props.imageWidths[obj]);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      case 'right_probe_body':
        paddingWidth.widths = Object.keys(props.imageWidths)
          .filter((obj) => props.imageWidths[obj] > 0)
          .filter((obj) => obj !== 'right_probe_body' && obj !== 'right')
          .map((obj) => props.imageWidths[obj]);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum: any, a: any) => partialSum + a, 0);
        break;
      case 'right':
        paddingWidth.widths = Object.keys(props.imageWidths)
          .filter((obj) => props.imageWidths[obj] > 0)
          .filter((obj) => obj !== 'right')
          .map((obj) => props.imageWidths[obj]);
        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      default:
        break;
    }
    return paddingWidth;
  };

  const renderSwitchFunction = () => {
    let paddingWidth = getPaddingWidth(imageData.position);
    let imageY = imageData.m12_connector
      ? props.stageHeight / 2 + (connectorImage.naturalHeight * props.canvasScale - 25 * props.canvasScale)
      : props.stageHeight / 2;
    let imageX = 0;
    switch (imageData.position) {
      case 'left':
        imageX = (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2;
        if (props.selectedTransmitterOptions.probe_connection_cable === '0' && imageData.without_cable_to_transmitter && props.centerAnchor[0] !== undefined) {
          // imageX = ((props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2) + (getPaddingWidth("center").padding * props.canvasScale) + (IMAGE_PADDING * getPaddingWidth("center").widths.length * props.canvasScale)
          imageX = props.centerAnchor[0][0];
          imageY = props.centerAnchor[0][1] - 6;
          return (
            <>
              <KonvaImage
                image={image}
                x={imageX}
                y={imageY}
                offsetY={15 * props.canvasScale}
                offsetX={image.width / 2}
                // onmouseenter={handleOnMouseEnter}
                // onmouseout={handleOnMouseOut}
                // onTap={handleOnMouseEnter}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                filters={hovered ? [Konva.Filters.RGB] : []}
                name={'left'}
              />
            </>
          );
        } else {
          return (
            <>
              <KonvaImage
                image={image}
                x={imageX}
                y={imageY}
                offsetY={image.height / 2}
                // onmouseenter={handleOnMouseEnter}
                // onmouseout={handleOnMouseOut}
                // onTap={handleOnMouseEnter}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                filters={hovered ? [Konva.Filters.RGB] : []}
                name={'left'}
              />
              {imageData.m12_connector && props.selectedTransmitter !== null && (
                <KonvaImage
                  image={connectorImage}
                  x={
                    imageX +
                    imageData.anchors[0][0] * image.naturalWidth * props.canvasScale -
                    (connectorImage.naturalWidth * props.canvasScale) / 2
                  }
                  y={
                    props.transmitterType === 'fixed'
                      ? imageY -
                        (image.naturalHeight * props.canvasScale) / 2 +
                        30 * props.canvasScale +
                        imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                      : imageY -
                        (image.naturalHeight * props.canvasScale) / 2 + 15
                        +imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                  }
                  // onmouseenter={handleOnMouseEnter}
                  // onmouseout={handleOnMouseOut}
                  offsetX={connectorImage.width}
                  rotation={imageData.anchors[0][1] < 0.5 ? 180 : 0}
                  scale={{ x: props.canvasScale, y: props.canvasScale }}
                  name={'center'}
                />
              )}
            </>
          );
        }
      case 'left_probe_body':
        imageX =
          (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2 +
          paddingWidth.padding * props.canvasScale +
          IMAGE_PADDING * paddingWidth.widths.length * props.canvasScale;
        imageY = imageData.m12_connector
          ? props.stageHeight / 2 - (connectorImage.naturalHeight * props.canvasScale - 25 * props.canvasScale)
          : props.stageHeight / 2;
        if (
          props.selectedTransmitterOptions.probe_connection_cable === '0' &&
          imageData.without_cable_to_transmitter &&
          props.selectedTransmitter !== undefined &&
          props.selectedTransmitter?.probe_to_transmitter
        ) {
          // imageX = ((props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2) + (getPaddingWidth("center").padding * props.canvasScale) + (IMAGE_PADDING * getPaddingWidth("center").widths.length * props.canvasScale)
          imageX = props.centerAnchor[0][0];
          imageY = props.centerAnchor[0][1] - 7;
          return (
            <>
              <KonvaImage
                image={image}
                x={imageX}
                y={imageY}
                offsetY={15 * props.canvasScale}
                offsetX={image.width / 2}
                // onmouseenter={handleOnMouseEnter}
                // onmouseout={handleOnMouseOut}
                // onTap={handleOnMouseEnter}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                filters={hovered ? [Konva.Filters.RGB] : []}
                name={'left_probe_body'}
              />
            </>
          );
        } else {
          return (
            <>
              <KonvaImage
                image={image}
                x={imageX}
                y={imageY}
                offsetY={image.height / 2}
                // onmouseenter={handleOnMouseEnter}
                // onmouseout={handleOnMouseOut}
                // onTap={handleOnMouseEnter}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                filters={hovered ? [Konva.Filters.RGB] : []}
                offsetX={image.width}
                rotation={180}
                name={'left_probe_body'}
                // strokeWidth={1} // border width
                // stroke="blue" // border color
              />
              {imageData.m12_connector && props.selectedTransmitter !== null && (
                <KonvaImage
                  image={connectorImage}
                  x={imageX + imageData.anchors[0][0] * (image.naturalWidth * props.canvasScale) - (connectorImage.width * props.canvasScale) / 2}
                  y={
                    imageY -
                    (image.naturalHeight * props.canvasScale) / 2 -
                    30 * props.canvasScale +
                    imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                  }
                  // onmouseenter={handleOnMouseEnter}
                  // onmouseout={handleOnMouseOut}
                  scale={{ x: props.canvasScale, y: props.canvasScale }}
                  name={'center'}
                />
              )}
            </>
          );
        }
      case 'center':
        // imageY = props.stageHeight / 2;
        imageX =
          (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2 +
          paddingWidth.padding * props.canvasScale +
          IMAGE_PADDING * paddingWidth.widths.length * props.canvasScale;
        if (props.probeConnectedToTransmitter) {
          imageY = props.stageHeight / 2 - (image.height * props.canvasScale) / 2;
        }
        imageY = imageData.m12_connector
          ? props.stageHeight / 2 - (connectorImage.naturalHeight * props.canvasScale - 25 * props.canvasScale)
          : props.stageHeight / 2;
        return (
          <>
            {!props.probeConnectedToTransmitter && (imageData.label[i18next.language as keyof typeof imageData.label] === 'Indigo202' || imageData.label[i18next.language as keyof typeof imageData.label] === 'Indigo201') && (
              <KonvaImage
                image={connectorImage}
                x={imageX + imageData.anchors[0][0] * (image.naturalWidth * props.canvasScale) - (connectorImage.width * props.canvasScale) / 2}
                y={
                  imageY -
                  (image.naturalHeight * props.canvasScale) / 2 -
                  30 * props.canvasScale +
                  imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                }
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                name={'center'}
              />
            )}
            {imageData.m12_connector && props.selectedTransmitter !== null && (
              <KonvaImage
                image={connectorImage}
                x={
                  imageX + imageData.anchors[0][0] * image.naturalWidth * props.canvasScale - (connectorImage.naturalWidth * props.canvasScale) / 2
                }
                y={
                  props.transmitterType === 'fixed'
                    ? imageY -
                      (image.naturalHeight * props.canvasScale) / 2 +
                      30 * props.canvasScale +
                      imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                    : imageY -
                      (image.naturalHeight * (props.canvasScale < 0.3 ? props.canvasScale - 0.015 : props.canvasScale)) / 2 +
                      10 +
                      imageData.anchors[0][1] * (image.naturalHeight * (props.canvasScale < 0.3 ? props.canvasScale - 0.015 : props.canvasScale))
                }
                rotation={props.transmitterType === 'fixed' ? 180 : 0}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                name={'center'}
              />
            )}
            {props.selectedTransmitter?.m12_connector && props.hasSecondProbe && (
              <KonvaImage
                image={connectorImage}
                x={
                  imageX + imageData.anchors[1][0] * image.naturalWidth * props.canvasScale - (connectorImage.naturalWidth * props.canvasScale) / 2
                }
                y={
                  props.transmitterType === 'fixed'
                    ? imageY -
                      (image.naturalHeight * props.canvasScale) / 2 +
                      30 * props.canvasScale +
                      imageData.anchors[1][1] * (image.naturalHeight * props.canvasScale)
                    : imageY -
                      (image.naturalHeight * (props.canvasScale < 0.3 ? props.canvasScale - 0.015 : props.canvasScale)) / 2 +
                      10 +
                      imageData.anchors[1][1] * (image.naturalHeight * (props.canvasScale < 0.3 ? props.canvasScale - 0.015 : props.canvasScale))
                }
                rotation={props.transmitterType === 'fixed' ? 180 : 0}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                name={'center'}
              />
            )}
            <KonvaImage
              image={image}
              x={imageX}
              y={imageY}
              offsetY={image.height / 2}
              scale={{ x: props.canvasScale, y: props.canvasScale }}
              filters={hovered ? [Konva.Filters.RGB] : []}
              name={'center'}
            />
          </>
        );
      case 'right_probe_body':
        imageX =
          (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2 +
          paddingWidth.padding * props.canvasScale +
          IMAGE_PADDING * paddingWidth.widths.length * props.canvasScale;
        imageY = imageData.m12_connector
          ? props.stageHeight / 2 - (connectorImage.naturalHeight * props.canvasScale - 25 * props.canvasScale)
          : props.stageHeight / 2;
        return (
          <>
            <KonvaImage
              image={image}
              x={imageX}
              y={imageY}
              offsetY={image.height / 2}
              scale={{ x: props.canvasScale, y: props.canvasScale }}
              filters={hovered ? [Konva.Filters.RGB] : []}
              offsetX={image.width}
              rotation={180}
              name={'right_probe_body'}
            />
            {imageData.m12_connector && props.selectedTransmitter !== null && (
              <KonvaImage
                image={connectorImage}
                x={imageX + imageData.anchors[0][0] * (image.naturalWidth * props.canvasScale) - (connectorImage.width * props.canvasScale) / 2}
                y={
                  imageY -
                  (image.naturalHeight * props.canvasScale) / 2 -
                  30 * props.canvasScale +
                  imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                }
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                name={'center'}
              />
            )}
          </>
        );
      case 'right':
        imageX =
          (props.stageWidth - totalWidth + IMAGE_PADDING * props.canvasScale) / 2 +
          paddingWidth.padding * props.canvasScale +
          IMAGE_PADDING * paddingWidth.widths.length * props.canvasScale;
        return (
          <>
            <KonvaImage
              image={image}
              x={imageX}
              y={imageY}
              offsetY={image.height / 2}
              scale={{ x: imageData.mirrored ? -props.canvasScale : props.canvasScale, y: props.canvasScale }}
              filters={hovered ? [Konva.Filters.RGB] : []}
              offsetX={imageData.mirrored ? image.width : 0}
              name={'right'}
            />
            {imageData.m12_connector && props.selectedTransmitter !== null && (
              <KonvaImage
                image={connectorImage}
                x={
                  imageX +
                  imageData.anchors[0][0] * image.naturalWidth * props.canvasScale -
                  (connectorImage.naturalWidth * props.canvasScale) / 2
                }
                y={
                  props.transmitterType === 'fixed'
                    ? imageY -
                      (image.naturalHeight * props.canvasScale) / 2 +
                      30 * props.canvasScale +
                      imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                    : imageY - (image.naturalHeight * props.canvasScale) / 2 + 15 +imageData.anchors[0][1] * (image.naturalHeight * props.canvasScale)
                }
                offsetX={connectorImage.width}
                rotation={imageData.anchors[0][1] < 0.5 ? 180 : 0}
                scale={{ x: props.canvasScale, y: props.canvasScale }}
                name={'center'}
              />
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{image ? renderSwitchFunction() : ''}</>;
};

export default CanvasImage;
