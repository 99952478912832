import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@vaisala-rockhopper/components';
import './MainHeader.scss';

const MainHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={`main-header`}>
      <Heading
        level={1}
      >
        {t('ui.main.heading')}
      </Heading>
      <Heading
        level={2}
      >
          {t('ui.main.subheading')}
      </Heading>
    </div>
  );
};

export default MainHeader;