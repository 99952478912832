import urlDictionary from './urlDictionary.json';
import { ProductConfiguration } from './ConfigurationFunctions';

const createShareUrl = () => {

    let storageConfiguration = localStorage.getItem("selected_measurements");
    let storageSelections = localStorage.getItem("product_selections");
    let urlConfiguration: ProductConfiguration = JSON.parse(storageConfiguration ?? "");
    let urlSelection: ProductConfiguration = JSON.parse(storageSelections ?? "");

    // const newUrlConfiguration = Object.keys(urlConfiguration).filter(k => urlConfiguration[k]);

    let urlParameters = "";

    urlConfiguration.forEach((val: any, i: number) => {
        urlParameters += urlDictionary.measurements[val as keyof typeof urlDictionary.measurements];
    });

    const params = new URLSearchParams();
    params.append("c", urlParameters)

    const newUrlSelection = Object.keys(urlSelection).filter(k => urlSelection[k].active)

    urlParameters = "";

    let probes = urlDictionary.selections['probes' as keyof typeof urlDictionary.selections]
    let transmitters = urlDictionary.selections['transmitters' as keyof typeof urlDictionary.selections]
    newUrlSelection.forEach((val, i) => {
        const newParam = probes[urlSelection[val].selected as keyof typeof probes] || transmitters[urlSelection[val].selected as keyof typeof transmitters];
        urlParameters += newParam
    });
    params.append("s", urlParameters);

    urlParameters = "";

    newUrlSelection.forEach((val, i) => {
        if (val.includes("probe")) {
            if (urlSelection[val].probe_cable !== "") {
                urlParameters += urlDictionary.selections[val + "_cable" as keyof typeof urlDictionary.selections] + ('0' + urlSelection[val].probe_cable).slice(-2)
            }
            if (urlSelection[val].temperature_compensation) {
                urlParameters += urlDictionary.selections["temperature_compensation" as keyof typeof urlDictionary.selections]
            }
        } else {
            let propsToCheck = ["display", "power_option", "probe_connection_cable", "temperature_compensation"]
            let propsToSave = Object.keys(urlSelection.transmitter).filter(k => urlSelection.transmitter[k] !== "")
            propsToSave = propsToSave.filter(val => propsToCheck.includes(val))
            propsToSave.forEach((val, i) => {
                if (val === "power_option") {
                    urlParameters += urlDictionary.selections["transmitter_" + val + "_" + urlSelection.transmitter[val] as keyof typeof urlDictionary.selections]
                } else if (val === "probe_connection_cable") {
                    urlParameters += urlDictionary.selections["transmitter_" + val as keyof typeof urlDictionary.selections] + ('0' + urlSelection.transmitter[val]).slice(-2)
                } else {
                    urlParameters += urlDictionary.selections["transmitter_" + val as keyof typeof urlDictionary.selections] + urlSelection.transmitter[val]
                }
            })
        }
    })

    params.append("t", urlParameters)

    const embed = document.querySelector('#indigo-selector.embed');
    if (embed) {
        const url = new URL(window.origin + window.location.pathname);
        url.search = params.toString();
        return (
            url.href + '#indigo-selector'
        )
    } else {
        const url = new URL(window.origin);
        url.search = params.toString();
        return (
            url.href
        )
    }
}

export default createShareUrl;