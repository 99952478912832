import * as React from 'react';

const getStorageValues = (key: string, defaultValue: any) => {
    const saved = localStorage.getItem(key);
    let initial: string = "";
    if (saved) {
        initial = JSON.parse(saved);
    }

    return initial || defaultValue;
};

export const useLocalStorage = (key: string, defaultValue: any) => {
    const [value, setValue] = React.useState(() => {
        return getStorageValues(key, defaultValue);
    });

    React.useEffect(() => {
        if(key !== "url") {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    return [value, setValue];
}

export const setStorageFromParams = (key: string, value: any) => {
    localStorage.setItem(key, value);
}