import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { Heading } from '@vaisala-rockhopper/components';
import './ConfiguratorPlaceholder.scss';
import { ProbeProps } from '../configuration/ConfigurationFunctions';

type Props = {
  isConfigurationReady?: boolean
  filteredProbes: Array<ProbeProps>
}

const ConfiguratorPlaceholder = (props: Props) => {
  const { t } = useTranslation();

  if (props.isConfigurationReady) {
    return (
      <div className={`configurator-placeholder ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
          <Heading
              level={1}
          >
              {t('ui.placeholder.heading')}
          </Heading>
      </div>
    );
  } else {
    if (props.filteredProbes.length > 0) {
      return (
        <div className={`configurator-placeholder ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
            <Heading
                level={1}
            >
                {t('ui.placeholder.text')}<br />{t('ui.placeholder.text.linetwo')}
            </Heading>
        </div>
      );
    } else {
      return (
        <div className={`configurator-placeholder ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
            <Heading
                level={1}
            >
                {t('ui.placeholder.too_many')}
            </Heading>
        </div>
      );
    }
  }
};

export default ConfiguratorPlaceholder;