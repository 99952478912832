import urlDictionary from './urlDictionary.json';
import _ from 'lodash';

const getUrlParams = (urlWithParams: URL) => {
    const params = Object.fromEntries(new URLSearchParams(urlWithParams.search));
    const cValues: string[] = params.c ? params.c.match(/.{1,4}/g) ?? [] : [];
    const sValues: string[] = params.s ? params.s.match(/.{1,4}/g) ?? [] : [];
    const tValues: string[] = params.t ? params.t.match(/.{1,4}/g) ?? [] : [];

    let confValues: string[] = [];
    let selValues: {
        "probe1": string,
        "probe2": string,
        "transmitter": string
    } = {
        "probe1": "",
        "probe2": "",
        "transmitter": ""
    };
    let transValues: {
        "probe1": {},
        "probe2": {},
        "transmitter": {}
    } = {
        "probe1": {},
        "probe2": {},
        "transmitter": {}
    };

    let urlDictConf = urlDictionary.measurements;
    Object.keys(urlDictConf).forEach((val, i) => {
        if (cValues.includes(urlDictConf[val as keyof typeof urlDictConf])) {
            confValues.push(val)
        }
    });

    let urlDictSel = urlDictionary.selections;
    let urlDictSelProbes = urlDictionary.selections['probes' as keyof typeof urlDictionary.selections];
    let urlDictSelTransmitters = urlDictionary.selections['transmitters' as keyof typeof urlDictionary.selections];

    sValues.forEach((val, i) => {
        let found = Object.keys(urlDictSelProbes).find((key: any) => urlDictSelProbes[key as keyof typeof urlDictSelProbes] === val)
        if (found !== undefined) {
            if (selValues['probe1' as keyof typeof selValues] !== "") {
                selValues['probe2' as keyof typeof selValues] = found
            } else {
                selValues['probe1' as keyof typeof selValues] = found
            }
            return;
        }
        found = Object.keys(urlDictSelTransmitters).find((key: any) => urlDictSelTransmitters[key as keyof typeof urlDictSelTransmitters] === val)
        if (found !== undefined) {
            selValues = {...selValues, "transmitter" : found }
        }
    })

    // Checking custom probe and transmitter values
    if(tValues.some(el => el.includes('teco'))) {
        transValues.probe1 = _.assign(transValues.probe1, {"temperature_compensation": true})
    }

    let probe1_cable = tValues.find(el => el.includes('p1'))
    if (probe1_cable !== undefined) {
        transValues.probe1 = _.assign(transValues.probe1, {"probe_cable": probe1_cable.slice(-2).replace(/^0+/, '')})
    }
    let probe2_cable = tValues.find(el => el.includes('p2'))
    if (probe2_cable !== undefined) {
        transValues = {...transValues, "probe2" : {"probe_cable": probe2_cable.slice(-2).replace(/^0+/, '')} }
    }

    let transmitter_power_option = tValues.find(el => el.includes('tp'))
    if (transmitter_power_option !== undefined) {
        let found = Object.keys(urlDictSel).find((key: any) => urlDictSel[key as keyof typeof urlDictSel] === transmitter_power_option)
        if (found !== undefined) {
            transValues.transmitter = _.assign(transValues.transmitter, {"power_option": found.split('_').pop()} )
        }
    }
    let transmitter_display = tValues.find(el => el.includes('dis'))
    if (transmitter_display !== undefined) {
        transValues.transmitter = _.assign(transValues.transmitter, {"display": transmitter_display.slice(-1)} )
    }
    let transmitter_probe_connection_cable = tValues.find(el => el.includes('tc'))
    if (transmitter_probe_connection_cable !== undefined) {
        transValues.transmitter = _.assign(transValues.transmitter, {"probe_connection_cable": transmitter_probe_connection_cable.slice(-2).replace(/^0+/, '')} )
    }


    // Object.keys(urlDictSelProbes).forEach((val, i) => {
    //     sValues.includes(urlDictSelProbes[val as keyof typeof urlDictSelProbes])
    //     // if (sValues.includes(urlDictSelProbes[val as keyof typeof urlDictSelProbes])) {
    //     //     selValues.push(val)
    //     // }
    // });

    // Object.keys(urlDictSel).forEach((val, i) => {
    //     if (tValues.includes(urlDictSel[val as keyof typeof urlDictSel])) {
    //         transValues.push(val);
    //     }
    // })

    return {
        "conf_values": confValues,
        "sel_values": selValues,
        "trans_values": transValues
    }

}
export default getUrlParams;