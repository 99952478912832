import { connect } from "http2";
import { Line } from "react-konva";

type Props = {
    start: number[]
    end: number[]
    topStart: boolean
    topEnd: boolean
    canvasScale: number
    startImageWidth: number
    startImageAnchors: number[][]
    endImageWidth: number
    endImageAnchors: number[][]
    isRightSide: boolean
    probeCable: boolean
    cableLength: number
    transmitterCableLength: number
    m12Connector: boolean
    cableColor: string
    cableWidth: number
    transmitterType?: string
}

const DrawLine = (props: Props) => {

    const IMAGE_PADDING = 100;

    if (props.start.length > 0 && props.end.length > 0 && props.startImageAnchors.length > 0) {
        return (
            <>
                <Line
                    stroke={props.cableColor}
                    strokeWidth={props.cableWidth * props.canvasScale}
                    bezier={true}
                    hitStrokeWidth={25}
                    shadowColor="#000000"
                    shadowEnabled={props.cableWidth === 8 ? true : false}
                    shadowOpacity={1}
                    shadowBlur={2}
                    name={props.probeCable ? (props.isRightSide ? "right" : "left") : "center"}
                    sceneFunc={(ctx,shape) => {
                        let points = [];
                        if(!props.topStart) {
                            if(!props.topEnd) {
                                let connectorPadding = props.m12Connector ? 150 * props.canvasScale : 75 * props.canvasScale;
                                if (props.start[1] < props.end[1]) {
                                    if (props.m12Connector && !props.isRightSide) {
                                        connectorPadding = (props.end[1] - props.start[1] + 140) * props.canvasScale;
                                        if (props.end[1] - props.start[1] > 50) {
                                            connectorPadding = (props.end[1] - props.start[1]) + 30;
                                        }
                                    } else {
                                        if (props.m12Connector) {
                                            connectorPadding = (props.end[1] - props.start[1] + 140) * props.canvasScale
                                        } else {
                                            connectorPadding = (props.end[1] - props.start[1] + 75) * props.canvasScale
                                        }
                                        // connectorPadding = props.end[1] - props.start[1] + 140 * props.canvasScale;
                                    }
                                } else if (props.start[1] - props.end[1] > 35) {
                                    if (props.transmitterCableLength === 0) {
                                        connectorPadding = 30;
                                    } else {
                                        connectorPadding = (props.start[1] - props.end[1] + 140) * props.canvasScale;
                                        if (props.start[1] - props.end[1] > 50) {
                                            connectorPadding = (props.start[1] - props.end[1]) + 30;
                                        }
                                    }
                                } else {
                                    if (props.m12Connector) {
                                        connectorPadding = (props.start[1] - props.end[1] + 140) * props.canvasScale;
                                    } else {
                                        connectorPadding = 75 * props.canvasScale;
                                    }
                                }
                                if(props.isRightSide) {
                                    points = [
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - 30,
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.end[1] + connectorPadding,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.end[1] + connectorPadding,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.end[1] - 20,
                                    ]
                                } else {
                                    points = [
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - 30,
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + connectorPadding,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.start[1] + connectorPadding,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] - 20,
                                    ]
                                }
                            } else {
                                let connectorPadding = props.m12Connector ? 150 * props.canvasScale : 75 * props.canvasScale;
                                points = [
                                    props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - 30,
                                    props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + connectorPadding,
                                    props.end[0] + props.endImageWidth + (IMAGE_PADDING * props.canvasScale / 2), props.start[1] + connectorPadding,
                                    props.end[0] + props.endImageWidth + (IMAGE_PADDING * props.canvasScale / 2), props.end[1] - connectorPadding,
                                    props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] - connectorPadding,
                                    props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] + 20,
                                ]
                            }
                        } else {
                            if (!props.topEnd) {
                                if (props.start[0] < props.end[0]) {
                                    points = [
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - 25,
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + 50,
                                        props.end[0] - (IMAGE_PADDING * props.canvasScale / 2), props.start[1] + 50,
                                        props.end[0] - (IMAGE_PADDING * props.canvasScale / 2), props.end[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] + 20,
                                    ]
                                } else {
                                    if(props.isRightSide) {
                                        let connectorPadding = (props.start[0] - props.end[0] + 75) * props.canvasScale;
                                        if (props.topStart) {
                                            connectorPadding = 35
                                        }
                                        points = [
                                            props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + 25,
                                            props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - connectorPadding,
                                            props.start[0] - (IMAGE_PADDING * props.canvasScale / 2), props.start[1] - connectorPadding,
                                            props.start[0] - (IMAGE_PADDING * props.canvasScale / 2), props.end[1] + connectorPadding,
                                            props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.end[1] + connectorPadding,
                                            props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.end[1] - 20,
                                        ]
                                    } else {
                                        let connectorPadding = props.m12Connector ? 175 * props.canvasScale : 75 * props.canvasScale;
                                        points = [
                                            props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + 25,
                                            props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - connectorPadding,
                                            props.start[0] - (IMAGE_PADDING * props.canvasScale / 2), props.start[1] - connectorPadding,
                                            props.start[0] - (IMAGE_PADDING * props.canvasScale / 2), props.end[1] + 50,
                                            props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] + 50,
                                            props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] - 20,
                                        ]
                                    }
                                }
                            } else {
                                if (props.isRightSide) {
                                    points = [
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] + 25,
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[0][0]), props.start[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.start[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[1][0]), props.end[1] + 20,
                                    ]
                                } else {
                                    points = [
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[1][0]), props.start[1] + 25,
                                        props.start[0] + (props.startImageWidth * props.startImageAnchors[1][0]), props.end[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] - 50,
                                        props.end[0] + (props.endImageWidth * props.endImageAnchors[0][0]), props.end[1] + 20,
                                    ]
                                }
                            }
                        }

                        ctx.beginPath();
                        ctx.moveTo(points[0],points[1]);
                        if (points.length === 4) {
                            ctx.lineTo(points[2],points[3]);
                        } else {
                            let n = 0;

                            while (n < points.length-4) {
                                let deltaX1 = (points[n+2] - points[n+0]);
                                let deltaY1 = (points[n+3] - points[n+1]);
                                let br1 = Math.min(40,Math.max(Math.abs(deltaX1/1.65),Math.abs(deltaY1/1.65)));

                                let deltaX2 = (points[n+2+2] - points[n+0+2]);
                                let deltaY2 = (points[n+3+2] - points[n+1+2]);
                                let br2 = Math.min(40,Math.max(Math.abs(deltaX2/1.65),Math.abs(deltaY2/1.65)));

                                let br = Math.min(br1,br2);

                                let oneX = points[n+0] + (Math.abs(deltaX1) - br)*Math.sign(deltaX1);
                                let oneY = points[n+1] + (Math.abs(deltaY1) - br)*Math.sign(deltaY1);

                                ctx.lineTo(oneX, oneY);
                                n+=2;
                                let twoX = points[n+0] + (br)*Math.sign(deltaX2);
                                let twoY = points[n+1] + (br)*Math.sign(deltaY2);

                                ctx.quadraticCurveTo(points[n+0], points[n+1],twoX, twoY);
                            }
                            ctx.lineTo(points[points.length-2],points[points.length-1]);
                        }
                        ctx.strokeShape(shape);
                    }}
                />
                {/* <RenderToolTip /> */}
            </>
        );
    } else {
        return (<></>)
    }
}

const CanvasLine = (props: Props) => {
    return (
        <DrawLine
            {...props}
        />
    );
}

export default CanvasLine;