import React, { RefObject, useEffect } from 'react';
import CanvasImage from './CanvasImage';
import { useLocalStorage } from '../../configuration/LocalStorage';
import CanvasLine from './CanvasLine';
import { Infobox, ProbeProps, ProductConfiguration, TransmitterProps } from '../../configuration/ConfigurationFunctions';
import Konva from 'konva';
import i18next from 'i18next';

type Image = {
  id: number;
  src: { [key: string]: any };
  anchors: Array<number[]>;
  probe_body: string;
  m12_connector: boolean;
  position: string;
  label: string;
  infobox: Infobox;
  without_cable_to_transmitter: boolean;
};

type Props = {
  stageWidth: number;
  stageHeight: number;
  images: Array<Image>;
  selectedTransmitter: TransmitterProps | undefined;
  productSelections: ProductConfiguration;
  selectedTransmitterOptions: { display: string; power_option: string; probe_connection_cable: string; barometric_pressure: string };
  probeAttachments: Array<ProbeProps>;
  stage: RefObject<Konva.Stage>;
  updateToolTipData: (toolTipPos: number[], imageInfo: Image | null, hover: boolean) => void;
  hoveringElement: boolean;
  transmitterType?: string;
};

type ImageOnCanvas = {
  img: Image;
  naturalWidth: number;
  naturalHeight: number;
};

const initialImages = {
  left: { active: false, order: 1, image: null },
  left_probe_body: { active: false, order: 2, image: null },
  center: { active: false, order: 3, image: null },
  right_probe_body: { active: false, order: 4, image: null },
  right: { active: false, order: 5, image: null },
};

const imageReducer = (state: any, dispatch: any) => {
  const { type, position, image } = dispatch;
  switch (type) {
    case 'update':
      return { ...state, [position]: { image: image, active: true, order: state[position].order } };
    case 'remove':
      return { ...state, [position]: { image: null, active: false, order: state[position].order } };
  }
};

const DrawComponent = (props: Props) => {
  const [centerAnchor, setCenterAnchor] = useLocalStorage('center_anchor', []);
  const [imageWidths, setImageWidths] = React.useState({ left: 0, left_probe_body: 0, center: 0, right_probe_body: 0, right: 0 });
  const [imagesOnCanvas, setImagesOnCanvas] = React.useReducer(imageReducer, initialImages);
  const [sortedImagesOnCanvas, setSortedImagesOnCanvas] = React.useState<
    Array<{ image: { img: Image; naturalWidth: number; naturalHeight: number }; active: boolean; order: number }>
  >([]);

  const IMAGE_PADDING = 100;
  const [canvasScale, setCanvasScale] = React.useState(1);
  const [probeConnectedToTransmitter, setProbeConnectedToTransmitter] = React.useState(false);

  const onImageDrawn = (imageWidth: number, imagePos: string, newImage: ImageOnCanvas) => {
    if (imagePos === 'left_probe_body') {
      if (props.productSelections.probe1.probe_body) {
        setImagesOnCanvas({ type: 'update', position: imagePos, image: newImage });
        setImageWidths((oldWidth) => ({ ...oldWidth, [imagePos]: imageWidth }));
      }
    } else if (imagePos === 'right_probe_body') {
      if (props.productSelections.probe2.probe_body) {
        setImagesOnCanvas({ type: 'update', position: imagePos, image: newImage });
        setImageWidths((oldWidth) => ({ ...oldWidth, [imagePos]: imageWidth }));
      }
    } else {
      setImagesOnCanvas({ type: 'update', position: imagePos, image: newImage });
      setImageWidths((oldWidth) => ({ ...oldWidth, [imagePos]: imageWidth }));
    }
  };

  useEffect(() => {
    let probeInTransmitter = false;
    let imagesOnCanvasCopy = { ...imagesOnCanvas };
    let arrFromImages = Object.keys(imagesOnCanvasCopy).map((val) => {
      return imagesOnCanvasCopy[val];
    });
    let arrSorted = arrFromImages
      .filter((obj) => obj.active)
      .sort(function (a, b) {
        const positionOrder = ['left', 'left_probe_body', 'center', 'right_probe_body', 'right'];
        function getPosIndex(x: any) {
          return positionOrder.indexOf(x.position);
        }

        return getPosIndex(a.image.img) - getPosIndex(b.image.img);
      });

    setSortedImagesOnCanvas(arrSorted);

    if (arrSorted.length > 0) {
      if (props.selectedTransmitterOptions.probe_connection_cable === '0') {
        let clonedArray = JSON.parse(JSON.stringify(arrSorted));
        let transmitterCopy = clonedArray.find((val: any) => val.image.img.position === 'center');
        let probeInTransmitterCopy = clonedArray.find((val: any) => val.image.img.without_cable_to_transmitter);
        let objIndex = -1;
        if (transmitterCopy && probeInTransmitterCopy) {
          transmitterCopy.image.naturalHeight += probeInTransmitterCopy.image.naturalHeight;
          objIndex = arrSorted.findIndex((obj) => obj.image.img.position === 'center');
        }
        if (objIndex > -1) {
          probeInTransmitter = true;
          arrSorted.push(transmitterCopy);
        }
      }
      let highest = getMax(arrSorted);
      let paddingsToAccountFor = Object.keys(imageWidths).filter((obj) => imageWidths[obj as keyof typeof imageWidths] > 0).length;
      let currentTotalWidth = calculateTotalWidth(imageWidths) + IMAGE_PADDING * paddingsToAccountFor;

      let newHeightScale = 1;
      let newWidthScale = 1;

      if (currentTotalWidth > props.stageWidth) {
        newWidthScale = (props.stageWidth - 40) / currentTotalWidth;
      }
      if (highest > props.stageHeight - 300) {
        if (highest > 1000) {
          newHeightScale = Math.round(((props.stageHeight - 225) / highest) * 100) / 100;
        } else {
          newHeightScale = Math.round(((props.stageHeight - 300) / highest) * 100) / 100;
        }
      }

      let newScale = Math.min(newHeightScale, newWidthScale);

      if (canvasScale !== newScale && newScale !== 1) {
        setCanvasScale((prevState) => newScale);
      }
      setProbeConnectedToTransmitter(probeInTransmitter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesOnCanvas]);

  const getMax = (object: any) => {
    let max = 0;
    object.forEach((val: any, i: any) => {
      if (val.image.naturalHeight > max) {
        max = val.image.naturalHeight;
      }
    });

    return max;
  };

  useEffect(() => {
    if (props.productSelections) {
      if (Object.keys(imagesOnCanvas).length > 0) {
        let oldWidths = { ...imageWidths };
        Object.keys(props.productSelections).forEach((val: any, i: any) => {
          if (!props.productSelections[val].active) {
            switch (val) {
              case 'probe1':
                setImagesOnCanvas({ type: 'remove', position: 'left' });
                oldWidths = { ...oldWidths, left: 0 };
                break;
              case 'probe2':
                setImagesOnCanvas({ type: 'remove', position: 'right' });
                oldWidths = { ...oldWidths, right: 0 };
                break;
              case 'transmitter':
                setImagesOnCanvas({ type: 'remove', position: 'center' });
                oldWidths = { ...oldWidths, center: 0 };
                break;
              default:
                break;
            }
          }
          if (!props.productSelections[val].probe_body) {
            switch (val) {
              case 'probe1':
                setImagesOnCanvas({ type: 'remove', position: 'left_probe_body' });
                oldWidths = { ...oldWidths, left_probe_body: 0 };
                break;
              case 'probe2':
                setImagesOnCanvas({ type: 'remove', position: 'right_probe_body' });
                oldWidths = { ...oldWidths, right_probe_body: 0 };
                break;
              default:
                break;
            }
          }
        });
        setImageWidths(oldWidths);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productSelections]);

  const calculateTotalWidth = (totalW: any) => {
    const arr = Object.values(totalW);
    let sum = 0;
    arr.forEach((value: any) => {
      if (value > 0) {
        sum += value;
      }
    });
    return sum;
  };

  const getPaddingWidth = (imagePos: string) => {
    let paddingWidth = {
      widths: [0],
      padding: 0,
    };
    switch (imagePos) {
      case 'left':
        break;
      case 'left_probe_body':
        paddingWidth.widths = Object.keys(imagesOnCanvas)
          .filter((obj) => imagesOnCanvas[obj].active)
          .filter((obj) => imagesOnCanvas[obj].image.naturalWidth > 0)
          .filter((obj) => imagesOnCanvas[obj].image.img.position === 'left')
          .map((obj) => imagesOnCanvas[obj].image.naturalWidth);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      case 'center':
        paddingWidth.widths = Object.keys(imagesOnCanvas)
          .filter((obj) => imagesOnCanvas[obj].active)
          .filter((obj) => imagesOnCanvas[obj].image.naturalWidth > 0)
          .filter((obj) => imagesOnCanvas[obj].image.img.position === 'left' || imagesOnCanvas[obj].image.img.position === 'left_probe_body')
          .map((obj) => imagesOnCanvas[obj].image.naturalWidth);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      case 'right_probe_body':
        paddingWidth.widths = Object.keys(imagesOnCanvas)
          .filter((obj) => imagesOnCanvas[obj].active)
          .filter((obj) => imagesOnCanvas[obj].image.naturalWidth > 0)
          .filter((obj) => imagesOnCanvas[obj].image.img.position !== 'right_probe_body' && imagesOnCanvas[obj].image.img.position !== 'right')
          .map((obj) => imagesOnCanvas[obj].image.naturalWidth);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum: any, a: any) => partialSum + a, 0);
        break;
      case 'right':
        paddingWidth.widths = Object.keys(imagesOnCanvas)
          .filter((obj) => imagesOnCanvas[obj].active)
          .filter((obj) => imagesOnCanvas[obj].image.naturalWidth > 0)
          .filter((obj) => imagesOnCanvas[obj].image.img.position !== 'right')
          .map((obj) => imagesOnCanvas[obj].image.naturalWidth);

        paddingWidth.padding = paddingWidth.widths.reduce((partialSum, a) => partialSum + a, 0);
        break;
      default:
        break;
    }
    return paddingWidth;
  };

  const DrawCanvasLines = (componentProps: any) => {
    if (Object.keys(imagesOnCanvas).length > 1) {
      let prevImage: any;
      return (
        <>
          {sortedImagesOnCanvas.map((val: any, i: any) => {
            if (i > 0) {
              let paddingsToAccountFor = Object.keys(imageWidths).filter((obj) => imageWidths[obj as keyof typeof imageWidths] > 0).length;
              let currentTotalWidth = (calculateTotalWidth(imageWidths) + IMAGE_PADDING * paddingsToAccountFor) * canvasScale;

              let paddingWidthPrev = getPaddingWidth(prevImage.image.img.position);
              let paddingWidth = getPaddingWidth(val.image.img.position);

              let anchorStart = [0, 0];
              let anchorEnd = [0, 0];

              let isRightSide = false;
              let probeCable = true;
              let cableLength = 0;
              let transmitterCableLength = parseFloat(props.selectedTransmitterOptions.probe_connection_cable) ?? 0;

              let startImageWidth = val.image.naturalWidth * canvasScale;
              let startImageAnchors = val.image.img.anchors;
              let endImageWidth = prevImage.image.naturalWidth * canvasScale;
              let endImageAnchors = prevImage.image.img.anchors;

              // If Indigo 200 series transmitter and probe connection cable, add m12connector to transmitter
              const m12Connector =
                val.image.img.position === 'right'
                  ? prevImage.image.img.m12_connector
                  : (val.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'Indigo201' ||
                      val.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'Indigo202') &&
                    props.selectedTransmitterOptions.probe_connection_cable !== '0'
                  ? true
                  : val.image.img.m12_connector;

              const cableColor =
                prevImage.image.img.probe_body === 'hmp9' || val.image.img.probe_body === 'hmp9'
                  ? '#080b0d'
                  : prevImage.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'TMP1' ||
                    val.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'TMP1'
                  ? '#ffffff'
                  : '#b5b5b5'; // If HMP9, show black cable. If TMP1, show white cable
              const cableWidth =
                prevImage.image.img.probe_body === 'hmp9' || val.image.img.probe_body === 'hmp9'
                  ? 7
                  : prevImage.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'TMP1' ||
                    val.image.img.label[i18next.language as keyof typeof val.image.img.label] === 'TMP1'
                  ? 8
                  : 14;

              let topStart =
                val.image.img.position === 'left_probe_body'
                  ? val.image.img.anchors[1][1] < 0.5
                    ? true
                    : false
                  : val.image.img.anchors[0][1] < 0.5
                  ? true
                  : false;
              let topEnd =
                val.image.img.position === 'right'
                  ? endImageAnchors[1]
                    ? endImageAnchors[1][1] < 0.5
                      ? true
                      : false
                    : false
                  : endImageAnchors[0][1] < 0.5
                  ? true
                  : false;

              if (prevImage.image.img.position === 'left') {
                if (val.image.img.position === 'center') {
                  if (props.selectedTransmitterOptions.probe_connection_cable === '0') {
                    return false;
                  }
                  anchorStart = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                      paddingWidth.padding * canvasScale +
                      IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                    props.stageHeight / 2 -
                      (val.image.naturalHeight * canvasScale) / 2 +
                      val.image.img.anchors[0][1] * (val.image.naturalHeight * canvasScale),
                  ];
                  // console.log(props.stageHeight / 2, prevImage.image.naturalHeight * canvasScale / 2, (prevImage.image.img.anchors[0][1]*(prevImage.image.naturalHeight * canvasScale)))
                  anchorEnd = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2,
                    props.stageHeight / 2 -
                      (prevImage.image.naturalHeight * canvasScale) / 2 +
                      prevImage.image.img.anchors[0][1] * (prevImage.image.naturalHeight * canvasScale),
                  ];
                  cableLength = parseInt(props.selectedTransmitterOptions.probe_connection_cable);
                  probeCable = false;
                } else if (val.image.img.position === 'left_probe_body') {
                  if (props.selectedTransmitterOptions.probe_connection_cable === '0') {
                    if (props.selectedTransmitter !== null) {
                      topStart = false;
                      anchorStart = [
                        centerAnchor[0][0] - (val.image.naturalWidth * canvasScale) / 2,
                        centerAnchor[0][1] + val.image.naturalHeight * canvasScale,
                      ];
                    } else {
                      anchorStart = [
                        (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                          paddingWidth.padding * canvasScale +
                          IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                        props.stageHeight / 2 -
                          (val.image.naturalHeight * canvasScale) / 2 +
                          val.image.img.anchors[1][1] * (val.image.naturalHeight * canvasScale),
                      ];
                    }
                  } else {
                    anchorStart = [
                      (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                        paddingWidth.padding * canvasScale +
                        IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                      props.stageHeight / 2 -
                        (val.image.naturalHeight * canvasScale) / 2 +
                        val.image.img.anchors[1][1] * (val.image.naturalHeight * canvasScale),
                    ];
                  }
                  anchorEnd = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2,
                    props.stageHeight / 2 -
                      (prevImage.image.naturalHeight * canvasScale) / 2 +
                      prevImage.image.img.anchors[0][1] * (prevImage.image.naturalHeight * canvasScale),
                  ];
                  cableLength = parseInt(props.productSelections.probe1.probe_cable);
                  probeCable = true;
                }
              } else if (prevImage.image.img.position === 'left_probe_body') {
                if (props.selectedTransmitterOptions.probe_connection_cable === '0') {
                  return false;
                }
                anchorStart = [
                  (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                    paddingWidth.padding * canvasScale +
                    IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                  props.stageHeight / 2 -
                    (val.image.naturalHeight * canvasScale) / 2 +
                    val.image.img.anchors[0][1] * (val.image.naturalHeight * canvasScale),
                ];
                anchorEnd = [
                  (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                    paddingWidthPrev.padding * canvasScale +
                    IMAGE_PADDING * paddingWidthPrev.widths.length * canvasScale,
                  props.stageHeight / 2 -
                    (prevImage.image.naturalHeight * canvasScale) / 2 +
                    prevImage.image.img.anchors[0][1] * (prevImage.image.naturalHeight * canvasScale),
                ];

                cableLength = parseInt(props.selectedTransmitterOptions.probe_connection_cable);
                probeCable = false;
              }

              if (prevImage.image.img.position === 'center') {
                if (props.selectedTransmitterOptions.probe_connection_cable === '0') {
                  return false;
                }
                if (val.image.img.position === 'right_probe_body') {
                  anchorStart = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                      paddingWidth.padding * canvasScale +
                      IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                    props.stageHeight / 2 -
                      (val.image.naturalHeight * canvasScale) / 2 +
                      val.image.img.anchors[0][1] * (val.image.naturalHeight * canvasScale),
                  ];
                  anchorEnd = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                      paddingWidthPrev.padding * canvasScale +
                      IMAGE_PADDING * paddingWidthPrev.widths.length * canvasScale,
                    props.stageHeight / 2 -
                      (prevImage.image.naturalHeight * canvasScale) / 2 +
                      prevImage.image.img.anchors[1][1] * (prevImage.image.naturalHeight * canvasScale),
                  ];

                  cableLength = parseInt(props.selectedTransmitterOptions.probe_connection_cable);
                  probeCable = false;
                } else if (val.image.img.position === 'right') {
                  anchorStart = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                      paddingWidth.padding * canvasScale +
                      IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                    props.stageHeight / 2 -
                      (val.image.naturalHeight * canvasScale) / 2 +
                      val.image.img.anchors[0][1] * (val.image.naturalHeight * canvasScale),
                  ];
                  anchorEnd = [
                    (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                      paddingWidthPrev.padding * canvasScale +
                      IMAGE_PADDING * paddingWidthPrev.widths.length * canvasScale,
                    props.stageHeight / 2 -
                      (prevImage.image.naturalHeight * canvasScale) / 2 +
                      prevImage.image.img.anchors[1][1] * (prevImage.image.naturalHeight * canvasScale),
                  ];
                  cableLength = parseInt(props.selectedTransmitterOptions.probe_connection_cable);
                  probeCable = false;
                }

                isRightSide = true;
              } else if (prevImage.image.img.position === 'right_probe_body') {
                anchorStart = [
                  (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                    paddingWidth.padding * canvasScale +
                    IMAGE_PADDING * paddingWidth.widths.length * canvasScale,
                  props.stageHeight / 2 -
                    (val.image.naturalHeight * canvasScale) / 2 +
                    val.image.img.anchors[0][1] * (val.image.naturalHeight * canvasScale),
                ];
                anchorEnd = [
                  (props.stageWidth - currentTotalWidth + IMAGE_PADDING * canvasScale) / 2 +
                    paddingWidthPrev.padding * canvasScale +
                    IMAGE_PADDING * paddingWidthPrev.widths.length * canvasScale,
                  props.stageHeight / 2 -
                    (prevImage.image.naturalHeight * canvasScale) / 2 +
                    prevImage.image.img.anchors[1][1] * (prevImage.image.naturalHeight * canvasScale),
                ];
                isRightSide = true;
                cableLength = parseInt(props.productSelections.probe2.probe_cable);
                probeCable = true;
              }

              prevImage = val;

              return (
                <CanvasLine
                  key={i}
                  start={anchorStart}
                  end={anchorEnd}
                  topStart={topStart}
                  topEnd={topEnd}
                  canvasScale={canvasScale}
                  startImageWidth={startImageWidth}
                  startImageAnchors={startImageAnchors}
                  endImageWidth={endImageWidth}
                  endImageAnchors={endImageAnchors}
                  isRightSide={isRightSide}
                  probeCable={probeCable}
                  cableLength={cableLength}
                  transmitterCableLength={transmitterCableLength}
                  m12Connector={m12Connector ?? false}
                  cableColor={cableColor}
                  cableWidth={cableWidth}
                  transmitterType={props.transmitterType}
                />
              );
            } else {
              prevImage = val;
              return false;
            }
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <DrawCanvasLines imagesOnCanvas={imagesOnCanvas} />
      {props.images.map((val, index) => {
        return (
          <CanvasImage
            key={index}
            stageHeight={props.stageHeight}
            stageWidth={props.stageWidth}
            image={val}
            images={props.images}
            centerAnchor={centerAnchor}
            onCenterAnchorChange={setCenterAnchor}
            onImageDrawn={onImageDrawn}
            imageWidths={imageWidths}
            selectedTransmitter={props.selectedTransmitter}
            onCanvasScaleChanged={setCanvasScale}
            canvasScale={canvasScale}
            selectedTransmitterOptions={props.selectedTransmitterOptions}
            hasSecondProbe={imagesOnCanvas['right'].active}
            probeAttachments={props.probeAttachments}
            productSelections={props.productSelections}
            probeConnectedToTransmitter={probeConnectedToTransmitter}
            // onShowToolTip={props.updateToolTipData}
            hoveringElement={props.hoveringElement}
            transmitterType={props.transmitterType}
          />
        );
      })}
    </>
  );
};

export default DrawComponent;
