import { useState, useEffect, useRef } from 'react';
import Konva from 'konva';
import { Stage, Layer, Group, Rect, Text } from 'react-konva';
import DrawComponent from './canvas/DrawComponent';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import './ConfiguratorCanvas.scss';
import { ProbeProps, ProductConfiguration, TransmitterProps, initialAttachmentData, Infobox } from '../configuration/ConfigurationFunctions';
import i18next from 'i18next';

type Image = {
    id: number
    src: {[key: string]: any},
    anchors: Array<number[]>
    probe_body: string
    m12_connector: boolean
    position: string
    label: string
    infobox: Infobox
    without_cable_to_transmitter: boolean
    mirrored?: boolean
}

interface SelectedProductOptionsProps {
  selectedProbes?: Array<ProbeProps>
  selectedTransmitter?: TransmitterProps
}

interface ConfiguratorCanvasProps {
    filteredProbes: Array<ProbeProps>
    filteredSecondaryProbes: Array<ProbeProps>
    tempCompProbe: Array<ProbeProps>
    selectedProductOptions?: SelectedProductOptionsProps
    probeAttachments: Array<ProbeProps>
    selectedTransmitter: TransmitterProps | undefined
    selectedProductSelections: ProductConfiguration
    selectedTransmitterOptions: {display: string, power_option: string, probe_connection_cable: string, barometric_pressure: string}
    transmitterType?: string
    showOverlay?: boolean
    hoveredCable: string
}

const ConfiguratorCanvas = ({...props}: ConfiguratorCanvasProps) => {

    const { t } = useTranslation();

    const [images, setImages] = useState<Image[] | []>([]);

    const [ width, setWidth ] = useState(0);
    const [ height, setHeight ] = useState(0);
    const KonvaContainer = useRef<HTMLDivElement>(null);
    const stage = useRef<Konva.Stage>(null);
    const [stageWidth, setStageWidth] = useState(0);
    const [stageHeight, setStageHeight] = useState(0);
    const [selectedProductSelections, setSelectedProductSelections] = useState(() => {
        const initialValue = props.selectedProductSelections;
        return initialValue || [];
    });

    const [toolTipPos, setToolTipPos] = useState<number[]>([0,0]);
    const [imageInfo, setImageInfo] = useState<Image | null>(null);
    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (KonvaContainer.current !== null) {
            setWidth(KonvaContainer.current.offsetWidth)
            setHeight(KonvaContainer.current.offsetHeight)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [KonvaContainer.current]);
    useEffect(() => {
        if (stage.current !== null) {
            setStageWidth(stage.current.width());
            setStageHeight(stage.current.height());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage.current]);


    useEffect(() => {
      if(props.selectedProductOptions) {
        setSelectedProductSelections(props.selectedProductSelections);
      }
    }, [props.selectedProductOptions]);

    const getPosition = (type: string) => {
        switch(type) {
            case "probe1":
                return "left";
            case "transmitter":
                return "center";
            case "probe2":
                return "right";
            case "left_probe_body":
                return "left_probe_body";
            case "right_probe_body":
                return "right_probe_body";
            default:
                return "right";
        }
    }

    useEffect(() => {
        let newImages: Array<Image> = [];
        const attachmentData = initialAttachmentData()[0];
        Object.keys(selectedProductSelections).sort().forEach((value, index) => {
            if (selectedProductSelections[value as keyof typeof selectedProductSelections].active && selectedProductSelections[value as keyof typeof selectedProductSelections].imageData) {
                if (value === "probe1" && selectedProductSelections.probe1.imageData['probe_body'] !== "") {
                    newImages.push({
                        id: index,
                        src: {"default": attachmentData.images[selectedProductSelections.probe1.imageData['probe_body']]},
                        anchors: attachmentData.anchors,
                        probe_body: attachmentData.probe_body,
                        m12_connector: attachmentData.m12_connector,
                        position: "left_probe_body",
                        label: attachmentData.label,
                        infobox: attachmentData.infobox,
                        without_cable_to_transmitter: attachmentData.without_cable_to_transmitter,
                    });
                    index++
                }
                if (value === "probe2" && selectedProductSelections.probe2.imageData['probe_body'] !== "") {
                    newImages.push({
                        id: index,
                        src: {"default": attachmentData.images[selectedProductSelections.probe2.imageData['probe_body']]},
                        anchors: attachmentData.anchors,
                        probe_body: attachmentData.probe_body,
                        m12_connector: attachmentData.m12_connector,
                        position: "right_probe_body",
                        label: attachmentData.label,
                        infobox: attachmentData.infobox,
                        without_cable_to_transmitter: attachmentData.without_cable_to_transmitter
                    });
                    index++
                }
                const drawPos = getPosition(value);
                newImages.push({
                    id: index,
                    src: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['images'],
                    anchors: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['anchors'],
                    probe_body: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['probe_body'],
                    m12_connector: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['m12_connector'],
                    position: drawPos,
                    label: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['label'],
                    infobox: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['infobox'],
                    without_cable_to_transmitter: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['without_cable_to_transmitter'],
                    mirrored: selectedProductSelections[value as keyof typeof selectedProductSelections].imageData['mirrored'] ?? false
                });
            }
        });
        if (stage.current?.children !== undefined) {
            let canvasChildren = stage.current.children[0].children
            canvasChildren?.forEach((el, i) => {
                setShowTooltip(false)
                el.clearCache()
                el.filters([]);
            })
        }
        setImages(newImages);
    }, [selectedProductSelections])

    useEffect(() => {
        if (props.hoveredCable) {
            if (stage.current?.children !== undefined) {
                let canvasChildren = stage.current?.children[0].children
                if (canvasChildren !== undefined) {
                    let canvasLines = canvasChildren.filter(el => el.getClassName() === "Line");
                    switch(props.hoveredCable) {
                        case "left":
                            canvasLines = canvasLines.filter(el => el.attrs.name.includes("left"))
                            canvasLines.forEach((el, i) => {
                                el.attrs.stroke = "#1ab4e2"
                            })
                            break;
                        case "right":
                            canvasLines = canvasLines.filter(el => el.attrs.name.includes("right"))
                            canvasLines.forEach((el, i) => {
                                el.attrs.stroke = "#1ab4e2"
                            })
                            break;
                        case "center":
                            canvasLines = canvasLines.filter(el => el.attrs.name.includes("center"))
                            canvasLines.forEach((el, i) => {
                                el.attrs.stroke = "#1ab4e2"
                            })
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }, [props.hoveredCable])

    const updateToolTipData = (toolTipPos: number[], imageInfo: Image | null, hover: boolean) => {
        setToolTipPos(toolTipPos)
        setImageInfo(imageInfo)
        if (stage.current?.children !== undefined) {
            let canvasChildren = stage.current.children[0].children
            if (canvasChildren !== undefined) {
                let canvasImages = canvasChildren.filter(el => el.getClassName() === "Image")
                let canvasLines = canvasChildren.filter(el => el.getClassName() === "Line")
                if (imageInfo?.position.includes("left")) {
                    canvasImages = canvasImages.filter(el => el.attrs.name.includes("left"))
                    canvasLines = canvasLines.filter(el => el.attrs.name.includes("left"))
                } else if (imageInfo?.position.includes("right")) {
                    canvasImages = canvasImages.filter(el => el.attrs.name.includes("right"))
                    canvasLines = canvasLines.filter(el => el.attrs.name.includes("right"))
                } else {
                    canvasImages = canvasImages.filter(el => el.attrs.name.includes("center"))
                    canvasLines = canvasLines.filter(el => el.attrs.name.includes("center"))
                }
                canvasImages?.forEach((el, i) => {
                    if (hover) {
                        el.cache();
                        el.attrs.red = 180;
                        el.attrs.green = 180;
                        el.attrs.blue = 180;
                        el.filters([Konva.Filters.RGB]);
                    } else {
                        setShowTooltip(false)
                        // el.clearCache()
                        el.filters([]);
                    }
                })
                canvasLines?.forEach((el, i) => {
                    el.attrs.stroke = "#1ab4e2"
                })
            }
        }
        setShowTooltip(true)
    }

    const openLink = (url: string) => {
        window.open(url, '_blank');
    }

    const RenderToolTips = () => {
        if (showTooltip && imageInfo !== null) {
            let bulletPointsY = 60;
            return (
                <Group
                    x={toolTipPos[0]}
                    y={toolTipPos[1]}
                    offsetX={125 / 2}
                    offsetY={0}
                    listening={true}
                >
                    <Rect
                        x={0}
                        y={0}
                        width={400}
                        height={220}
                        fill="#1ab4e2"
                        shadowBlur={1}

                    />
                    <Text
                        text={imageInfo.infobox[i18next.language].title + ', ' + imageInfo.infobox[i18next.language].description}
                        fontSize={16}
                        fontStyle="bold"
                        lineHeight={1.25}
                        align="left"
                        x={15}
                        y={15}
                        fill="#fff"
                        width={350}
                        height={40}
                        listening={false}
                    />
                    {/* <Text
                        text={imageInfo.infobox[i18next.language].description}
                        fontSize={16}
                        align="left"
                        x={15}
                        y={50}
                        fill="#fff"
                        width={350}
                        height={30}
                        listening={false}
                    /> */}
                    {imageInfo.infobox[i18next.language].bulletpoints.map((val: string, i: number) => {
                        let height = 22;
                        if (val.length > 50) {
                            height += ~~(val.length / 50) * 22;
                        }
                        let drawY = bulletPointsY;
                        bulletPointsY += height
                        return (
                            <Text
                                key={i}
                                text={"• " + val}
                                fontSize={16}
                                lineHeight={1.25}
                                align="left"
                                x={15}
                                y={drawY}
                                fill="#fff"
                                width={350}
                                height={height}
                                listening={false}
                            />
                        )
                    })}
                    <Text
                        text={t('ui.read_more') + " »"}
                        fontSize={16}
                        // fontStyle={"bold"}
                        // textDecoration="underline"
                        align="left"
                        fill="#fff"
                        x={15}
                        y={bulletPointsY+10}
                        width={350}
                        height={40}
                        onClick={() => openLink(imageInfo.infobox[i18next.language].link)}
                        onTap={() => openLink(imageInfo.infobox[i18next.language].link)}
                        onMouseEnter={(e) => {
                            const container = e?.target?.getStage()?.container();
                            if (container) {
                                container.style.cursor = "pointer";
                            }
                        }}
                        onMouseLeave={(e) => {
                            const container = e?.target?.getStage()?.container();
                            if (container) {
                                container.style.cursor = "default";
                            }
                        }}
                    />
                    {imageInfo.probe_body !== "" && imageInfo.position !== "center" &&
                        <>
                            <Text
                                text={t('ui.probe_cable') + ": " + (imageInfo.position === "left" ? props.selectedProductSelections.probe1.probe_cable : props.selectedProductSelections.probe2.probe_cable) + " m"}
                                fontSize={14}
                                align="left"
                                x={15}
                                y={190}
                                fill="#fff"
                                width={350}
                                height={30}
                                listening={false}
                            />
                        </>
                    }
                    {props.selectedTransmitterOptions.probe_connection_cable !== "0" && imageInfo.position === "center" &&
                        <>
                            <Text
                                text={props.selectedProductSelections.probe2.active ? t('ui.probe_connection_cables') + ": " + props.selectedTransmitterOptions.probe_connection_cable + " m" : t('ui.probe_connection_cable') + ": " + props.selectedTransmitterOptions.probe_connection_cable + " m"}
                                fontSize={14}
                                align="left"
                                x={15}
                                y={190}
                                fill="#fff"
                                width={350}
                                height={30}
                                listening={false}
                            />
                        </>
                    }
                </Group>
            );
        } else {
            return (<></>);
        }
    }

    const [hoveringElement, setHoveringElement] = useState(false);

    return (
        <div id="konva-container" ref={KonvaContainer} className={`configurator-canvas ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
            <Stage
                width={width}
                height={height}
                ref={stage}
                container="konva-container"
                // onTap={(e) => {
                //     const emptySpace = e.target === e.target.getStage();
                //     if (!emptySpace) {
                //         setHoveringElement(true)
                //         return;
                //     }
                //     setHoveringElement(false)
                //     updateToolTipData([0,0], null, false)
                //  }}
                //  onMouseMove={(e) => {
                //     const emptySpace = e.target === e.target.getStage();
                //     if (!emptySpace) {
                //         setHoveringElement(true)
                //         return;
                //     }
                //     setHoveringElement(false)
                //     updateToolTipData([0,0], null, false)
                //  }}
            >
                <Layer>
                    {(stageHeight > 0) &&
                        <>
                            <DrawComponent
                                images={images}
                                stageWidth={stageWidth}
                                stageHeight={stageHeight}
                                selectedTransmitter={props.selectedTransmitter}
                                productSelections={props.selectedProductSelections}
                                selectedTransmitterOptions={props.selectedTransmitterOptions}
                                probeAttachments={props.probeAttachments}
                                stage={stage}
                                updateToolTipData={updateToolTipData}
                                hoveringElement={hoveringElement}
                                transmitterType={props.transmitterType}
                            />
                            {/* <RenderToolTips /> */}
                        </>
                    }
                </Layer>
            </Stage>
            {props.showOverlay && <div className="overlay"></div>}
        </div>
    );
};

export default ConfiguratorCanvas;
