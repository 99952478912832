import productData from '../products/productData.json';

export interface ProductConfiguration {
    [key: string]: any;
}

export interface Infobox {
    [key: string]: {
        title: string
        description: string
        link: string
        bulletpoints: string[]
    }
}

export interface ProbeProps {
    images: {[key: string]: any;}
    label: string
    value: string
    infobox: Infobox
    values: any
    anchors: number[][]
    probe_body: string
    m12_connector: boolean
    without_cable_to_transmitter: boolean
    cables?: any
    measures: string[]
    order: number,
    probe_type: {[key: string]: any;}
    mirrored?: boolean
}
export interface AttachmentProps {
    images: {[key: string]: any;}
    label: string
    value: string
    infobox: Infobox
    values: any
    anchors: number[][]
    probe_body: string
    m12_connector: boolean
    without_cable_to_transmitter: boolean
    cables?: any
    measures: string[]
    order: number
    probe_type: {[key: string]: any;}
}
interface TransmitterConnections {
    output: {[key: string]: string}
    type: string
    relays?: number
    channels?: number
}
export interface TransmitterProps {
    label: {[key: string]: string}
    value: string
    infobox: Infobox
    images: {[key: string]: any;}
    anchors?: number[][]
    measures?: string[]
    operating_powers?: Array<{
        label?: string
        value?: string
        supply?: string
        connections?: Array<TransmitterConnections>
    }>
    probe_connection_cables: string[],
    probe_to_transmitter: boolean,
    m12_connector?: boolean,
    transmitter_type:  {[key: string]: boolean},
    probe_type?: {[key: string]: any;},
    dual_probe: boolean
}

const INITIAL_CONFIGURATION: ProductConfiguration = {
    humidity: false,
    temperature: false,
    dewpoint: false,
    mio: false,
    co2: false,
    h2o2: false,
    humidity_low: false,
    humidity_normal: false,
    humidity_high: false,
    temperature_ambient: false,
    temperature_process: false,
    temperature_high: false,
    temperature_veryhigh: false,
    pressure_ambient: false,
    pressure_pressurized: false,
    co2_low: false,
    co2_high: false
}

const INITIAL_SELECTIONS: ProductConfiguration = {
    probe1: {
        active: true,
        selected: "",
        probe_cable: "",
        sensor_cable: "",
        probe_body: false
    },
    probe2: {
        active: false,
        selected: "",
        probe_cable: "",
        sensor_cable: "",
        probe_body: false
    },
    transmitter: {
        active: true,
        selected: "",
        connection_type: "",
        signal_type: "",
        display: "1",
        power_option: "",
        probe_connection_cable: "",
        barometric_pressure: "0"
    }
}

export const initialConfiguration = () => {
    const configuration: ProductConfiguration = INITIAL_CONFIGURATION;
    return configuration;
}

export const initialSelections = () => {
    const selections: ProductConfiguration = INITIAL_SELECTIONS;
    return selections;
}

export const initialProbeData = () => {
    let filteredProbes: Array<ProbeProps> = productData.products[0].probes;
    filteredProbes = filteredProbes.sort((a, b) => a.order > b.order ? 1 : -1);
    return filteredProbes;
}

export const initialTempCompProbeData = () => {
    let tempCompProbe: Array<ProbeProps> = productData.products[0].probes;
    tempCompProbe = tempCompProbe.filter(el => el.value === "TMP1");
    return tempCompProbe;
}

export const initialTransmitterData = () => {
    const filteredTransmitters: Array<TransmitterProps> = productData.products[0].transmitters;
    return filteredTransmitters;
}

export const initialAttachmentData = () => {
    const probeAttachments: Array<AttachmentProps> = productData.products[0].attachments;
    return probeAttachments;
}

export const filterProbes = (configuration: ProductConfiguration) => {
    let selectedValues = Object.keys(configuration).filter(function(k: string){return configuration[k as any]})

    let newFilteredProbes = initialProbeData();

    if (selectedValues.length > 0) {

        let filteredProbes = newFilteredProbes.filter((o: any) => {
            return selectedValues.every((v) => o.values[v] === true);
        });

        newFilteredProbes = filteredProbes;
    }

    return newFilteredProbes;
}

export const filterProbesByMeasurements = (measurements: string[], transmitterType: string) => {
    let filteredProbes = initialProbeData();
    let secondaryFiltering: Array<ProbeProps> = [];

    filteredProbes = filteredProbes.filter((o: any) => {
        return o.probe_type[transmitterType] === true
    })

    filteredProbes = filteredProbes.filter((o: any) => {
        return measurements.every((val) => o.measures.includes(val))
    })

    if (filteredProbes.length < 1) {
        secondaryFiltering = initialProbeData();
        secondaryFiltering = secondaryFiltering.filter((o: any) => {
            return o.probe_type[transmitterType] === true
        })
        let maxCount = 0;
        let bestSuitableProbes: Array<ProbeProps> = [];
        secondaryFiltering.forEach((val: any) => {
            let count = measurements.reduce((a: any, c: any) => a + val.measures.includes(c), 0);
            if (count > maxCount) {
                maxCount = count;
                bestSuitableProbes = [];
                bestSuitableProbes.push(val);
            } else if (count === maxCount) {
                bestSuitableProbes.push(val)
            }
        })

        bestSuitableProbes = bestSuitableProbes.sort((a, b) => a.order > b.order ? 1 : -1);
        const measurementsToCheck = [...bestSuitableProbes[0].measures]
        bestSuitableProbes = bestSuitableProbes.filter((o: any) => {
            return measurementsToCheck.every((v,i) => o.measures.includes(v))
        })

        let measurementsNotMet = measurements;
        bestSuitableProbes[0].measures.forEach((measurevalue) => {
            measurementsNotMet = measurementsNotMet.filter((val) => val !== measurevalue);
        })

        secondaryFiltering = secondaryFiltering.filter((o: any) => {
            return measurementsNotMet.every((val) => o.measures.includes(val))
        })

        if (measurementsNotMet.length > 0 && secondaryFiltering.length === 0) {
            filteredProbes = [];
        } else {
            filteredProbes = bestSuitableProbes;
        }


    }

    filteredProbes = filteredProbes.sort((a, b) => a.order > b.order ? 1 : -1);
    secondaryFiltering = secondaryFiltering.sort((a, b) => a.order > b.order ? 1 : -1);

    return  {
        probes: filteredProbes,
        secondary_probes: secondaryFiltering.length > 0 ? secondaryFiltering : []
    };
}

export const filterConditionOptions = (conditionsAvailable: string[], filteredProbes: Array<ProbeProps>) => {

     let conditionOptions: string[] = [];

     conditionsAvailable.forEach((val, i) => {
        filteredProbes.forEach((probe, index) => {
            let testValue = Object.keys(probe.values).filter((key) => {
                return key.indexOf(val + '_') === 0 && probe.values[key];
            })
            conditionOptions.push(...testValue);
        })
     })
     const uniqueOptions = removeDuplicates(conditionOptions)

     return uniqueOptions;
}

const removeDuplicates = (arr: string[]) => {
    var obj: any = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
        obj[arr[i]] = true;
    }
    for (var key in obj) {
        ret_arr.push(key);
    }
    return ret_arr;
}