import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { BodyText, Flex, Paper, Accordion, Heading } from '@vaisala-rockhopper/components';
import './ConfiguratorContent.scss';
import ConfiguratorSelects from './ConfiguratorSelects';
import ConfiguratorCanvas from './ConfiguratorCanvas';
import ConfiguratorPlaceholder from './ConfiguratorPlaceholder';
import { initialSelections, ProbeProps, ProductConfiguration, TransmitterProps } from '../configuration/ConfigurationFunctions';
import { setStorageFromParams, useLocalStorage } from '../configuration/LocalStorage';
import getUrlParams from '../configuration/getUrlParams';
import i18next from 'i18next';
import { ModalType } from '../modals/Modals';
import { OptionsConfig } from '../modals/OptionsModal';
import { useTranslation } from 'react-i18next';

interface ConfiguratorContentProps {
  isConfigurationReady?: boolean;
  showConfiguration: boolean;
  filteredProbes: Array<ProbeProps>;
  filteredSecondaryProbes: Array<ProbeProps>;
  tempCompProbe: Array<ProbeProps>;
  filteredTransmitters: Array<TransmitterProps>;
  selectedMeasurements: string[];
  probeAttachments: Array<ProbeProps>;
  loadedSavedConfiguration: boolean;
  onLoadedSavedConfigurationChange: (arg0: boolean) => void;
  transmitterType?: string;
  setTransmitterType: (value: string) => void;
  openModal: (type: ModalType, title?: string, optionsConfig?: OptionsConfig, onAccept?: VoidFunction, onReject?: VoidFunction) => void;
}

const ConfiguratorContent = ({ ...props }: ConfiguratorContentProps) => {
  const { t } = useTranslation();

  const [selectedProductOptions, setSelectedProductOptions] = React.useState({});
  const [dataFromUrl, setDataFromUrl] = React.useState<boolean | null>(null);
  const [selectedProductSelections, setSelectedProductSelections] = React.useState<ProductConfiguration>(() => {
    const url = new URL(window.location.href);
    const urlparams = Object.fromEntries(new URLSearchParams(url.search));
    let savedSelections = JSON.parse(localStorage.getItem('product_selections') ?? 'false');
    if (Object.keys(urlparams).length > 0 && Object.keys(urlparams).includes('s')) {
      setDataFromUrl(true);
      let paramsOnLoad = getUrlParams(url);
      savedSelections = initialSelections();
      savedSelections['probe1']['selected'] = paramsOnLoad['sel_values']['probe1' as keyof typeof paramsOnLoad['sel_values']];

      if (paramsOnLoad['trans_values']['probe1']['temperature_compensation' as keyof typeof paramsOnLoad['trans_values']['probe1']]) {
        savedSelections['probe1']['temperature_compensation'] = true;
      }
      if (paramsOnLoad['trans_values']['probe1']['probe_cable' as keyof typeof paramsOnLoad['trans_values']['probe1']]) {
        savedSelections['probe1']['probe_cable'] =
          paramsOnLoad['trans_values']['probe1']['probe_cable' as keyof typeof paramsOnLoad['trans_values']['probe1']];
      }

      if (
        paramsOnLoad['sel_values']['probe2' as keyof typeof paramsOnLoad['sel_values']] !== null &&
        paramsOnLoad['sel_values']['probe2' as keyof typeof paramsOnLoad['sel_values']] !== ''
      ) {
        savedSelections['probe2']['selected'] = paramsOnLoad['sel_values']['probe2' as keyof typeof paramsOnLoad['sel_values']];
        savedSelections['probe2']['active'] = true;
      }
      if (
        paramsOnLoad['sel_values']['transmitter' as keyof typeof paramsOnLoad['sel_values']] !== null &&
        paramsOnLoad['sel_values']['transmitter' as keyof typeof paramsOnLoad['sel_values']] !== ''
      ) {
        if (paramsOnLoad['sel_values']['transmitter' as keyof typeof paramsOnLoad['sel_values']] !== 'Indigo80') {
          // props.setTransmitterType('fixed');
        }
        savedSelections['transmitter']['selected'] = paramsOnLoad['sel_values']['transmitter' as keyof typeof paramsOnLoad['sel_values']];
        savedSelections['transmitter']['active'] = true;
      }

      Object.keys(paramsOnLoad['trans_values']['transmitter']).forEach((element) => {
        savedSelections['transmitter'][element] =
          paramsOnLoad['trans_values']['transmitter'][element as keyof typeof paramsOnLoad['trans_values']['transmitter']];
      });

      // paramsOnLoad['sel_values'].forEach((key, i) => {
      //   savedSelections["probe1"]["selected"] = paramsOnLoad[0];
      //   savedSelections["transmitter"]["selected"] = paramsOnLoad[1];
      // })
      setStorageFromParams('product_selections', JSON.stringify(savedSelections));
    } else {
      setDataFromUrl(false)
      savedSelections = JSON.parse(localStorage.getItem('product_selections') ?? 'false');
    }
    if (!savedSelections) {
      const initialSelects = initialSelections();
      setStorageFromParams('product_selections', JSON.stringify(initialSelects));
    }
    // window.history.replaceState(null, "", window.location.pathname);
    return savedSelections || initialSelections();
  });
  const [filteredTransmitters, setFilteredTransmitters] = React.useState(props.filteredTransmitters ?? []);

  const [selectedTransmitter, setSelectedTransmitter] = useLocalStorage('selected_transmitter', null);

  const [showOverlay, setShowOverlay] = React.useState(false);

  const [hoveredCable, setHoveredCable] = React.useState('');

  const onHoveredCableChange = (value: string) => {
    switch (value) {
      case "probe1_cable":
        setHoveredCable("left")
        break;
      case "probe2_cable":
        setHoveredCable("right")
        break;
      case "probe_connection_cable":
        setHoveredCable("center")
        break;
      default:
        setHoveredCable('');
        break;
    }
  }

  React.useEffect(() => {
    if (props.filteredTransmitters && props.filteredTransmitters.length > 0) {
      if (dataFromUrl) {
        onConfiguratorSelectChange(selectedProductSelections.transmitter.selected, 'transmitter');
        let selectedInFiltered = props.filteredTransmitters.find((obj) => obj.value === selectedProductSelections?.transmitter?.selected);
        setSelectedTransmitter(selectedInFiltered);
      } else {
        setFilteredTransmitters(props.filteredTransmitters);
        let selectedInFiltered = props.filteredTransmitters.some((obj) => obj.value === selectedTransmitter?.value);
        if (selectedInFiltered) {
          if (props.filteredSecondaryProbes.length > 0) {
            onConfiguratorSelectChange(props.filteredTransmitters[0].value, 'transmitter');
          } else {
            onConfiguratorSelectChange(selectedTransmitter.value, 'transmitter');
          }
        } else {
          onConfiguratorSelectChange(props.filteredTransmitters[0].value, 'transmitter');
        }
      }
    }
  }, [props.filteredTransmitters]);

  // Transmitter selections
  const [selectedTransmitterOptions, setSelectedTransmitterOptions] = React.useState({
    display: selectedProductSelections.transmitter.display || '1',
    power_option: selectedProductSelections.transmitter.power_option || '',
    probe_connection_cable: selectedProductSelections.transmitter.probe_connection_cable || '',
    barometric_pressure: selectedProductSelections.transmitter.barometric_pressure || '0',
  });

  React.useEffect(() => {
    if (selectedProductSelections.transmitter.selected !== null && selectedTransmitter !== null) {
      if (selectedProductSelections.transmitter.selected !== '' && selectedProductSelections.transmitter.imageData) {
        if (selectedProductSelections.transmitter.imageData.operating_powers !== undefined) {
          if (!selectedTransmitter.operating_powers.find((el: any) => el.value === selectedProductSelections.transmitter.power_option)) {
            if (selectedProductSelections.transmitter.imageData.operating_powers.length > 0) {
              onSelectedTransmitterOptionsChange('power_option', selectedProductSelections.transmitter.imageData.operating_powers[0].value);
            }
          }
          if (selectedProductSelections.transmitter.selected === 'Indigo202') {
            onSelectedTransmitterOptionsChange('display', '1');
          }
        }
      } else {
        setSelectedTransmitterOptions({ display: '1', power_option: '', probe_connection_cable: '', barometric_pressure: '0' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransmitter]);

  const onSelectedTransmitterOptionsChange = (select_key: string, selected: string) => {
    const oldSelectedTransmitterOptions = { ...selectedTransmitterOptions };
    setSelectedTransmitterOptions((prevState) => ({ ...oldSelectedTransmitterOptions, [select_key]: selected }));

    let selectedProductSelectionsCopy = JSON.parse(localStorage.getItem('product_selections') ?? 'false');
    selectedProductSelectionsCopy.transmitter = { ...selectedProductSelectionsCopy.transmitter, [select_key]: selected };
    setSelectedProductSelections(selectedProductSelectionsCopy);
    setStorageFromParams('product_selections', JSON.stringify(selectedProductSelectionsCopy));
  };

  React.useEffect(() => {
    if (props.filteredTransmitters.length < 2 && props.transmitterType === 'fixed' && props.selectedMeasurements.includes('barometric_pressure')) {
      onSelectedTransmitterOptionsChange('barometric_pressure', '1');
    } else {
      onSelectedTransmitterOptionsChange('barometric_pressure', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filteredTransmitters]);

  // React.useEffect(() => {
  //   const initialSelects = initialSelections();
  //   setSelectedProductSelections(initialSelects)
  //   setStorageFromParams('product_selections', JSON.stringify(initialSelects));
  // }, [props.transmitterType])

  const onConfiguratorSelectChange = React.useCallback(
    (value: string, position: string) => {
      let oldSelectedOptions: any = { ...selectedProductOptions } || {};
      let selectedProductSelectionsCopy = JSON.parse(localStorage.getItem('product_selections') ?? 'false');
      setShowOverlay(true);

      // Hide overlay after 2 seconds
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);

      switch (position) {
        case 'transmitter':
          props.filteredTransmitters.forEach((val) => {
            if (value === 'remove') {
              setSelectedTransmitter(null);
              oldSelectedOptions.transmitter = '';
              selectedProductSelectionsCopy.transmitter = {
                ...selectedProductSelectionsCopy.transmitter,
                selected: '',
                active: false,
                imageData: val,
              };
              selectedProductSelectionsCopy.probe2 = {
                ...selectedProductSelectionsCopy.probe2,
                selected: '',
                active: false,
                imageData: val,
                probe_body: false,
              };
            } else {
              if (val.value === value) {
                setSelectedTransmitter(val);
                oldSelectedOptions.transmitter = val;
                selectedProductSelectionsCopy.transmitter = {
                  ...selectedProductSelectionsCopy.transmitter,
                  selected: val.value,
                  active: true,
                  imageData: val,
                };
                if (value !== 'Indigo520' && value !== 'Indigo80') {
                  selectedProductSelectionsCopy.probe2 = {
                    ...selectedProductSelectionsCopy.probe2,
                    selected: '',
                    active: false,
                    imageData: val,
                    probe_body: false,
                  };
                }
              }
            }
          });
          break;
        case 'probe1':
          props.filteredProbes.forEach((val, i) => {
            if (val.value === value) {
              if (selectedProductOptions !== undefined) {
                oldSelectedOptions.probe1 = val;
                if (val.probe_body) {
                  selectedProductSelectionsCopy.probe1 = {
                    ...selectedProductSelectionsCopy.probe1,
                    selected: val.value,
                    active: true,
                    imageData: val,
                    probe_body: true,
                  };
                } else {
                  selectedProductSelectionsCopy.probe1 = {
                    ...selectedProductSelectionsCopy.probe1,
                    selected: val.value,
                    active: true,
                    imageData: val,
                    probe_body: false,
                  };
                }
              }
              // }
            }
          });
          break;
        case 'probe2':
          if (props.filteredSecondaryProbes.length > 0) {
            props.filteredSecondaryProbes.forEach((val, i) => {
              if (value === 'remove') {
                oldSelectedOptions.probe2 = '';
                selectedProductSelectionsCopy.probe2 = {
                  ...selectedProductSelectionsCopy.probe2,
                  selected: '',
                  active: false,
                  imageData: val,
                  probe_body: false,
                };
              } else {
                if (val.value === value) {
                  if (selectedProductOptions !== undefined) {
                    oldSelectedOptions.probe2 = val;
                    if (val.probe_body) {
                      selectedProductSelectionsCopy.probe2 = {
                        ...selectedProductSelectionsCopy.probe2,
                        selected: val.value,
                        active: true,
                        imageData: val,
                        probe_body: true,
                      };
                    } else {
                      selectedProductSelectionsCopy.probe2 = {
                        ...selectedProductSelectionsCopy.probe2,
                        selected: val.value,
                        active: true,
                        imageData: val,
                        probe_body: false,
                      };
                    }
                  }
                }
              }
            });
          } else {
            props.filteredProbes.forEach((val, i) => {
              if (value === 'remove') {
                oldSelectedOptions.probe2 = '';
                selectedProductSelectionsCopy.probe2 = {
                  ...selectedProductSelectionsCopy.probe2,
                  selected: '',
                  active: false,
                  imageData: val,
                  probe_body: false,
                };
              } else {
                if (val.value === value) {
                  if (selectedProductOptions !== undefined) {
                    oldSelectedOptions.probe2 = val;
                    if (val.probe_body) {
                      selectedProductSelectionsCopy.probe2 = {
                        ...selectedProductSelectionsCopy.probe2,
                        selected: val.value,
                        active: true,
                        imageData: val,
                        probe_body: true,
                      };
                    } else {
                      selectedProductSelectionsCopy.probe2 = {
                        ...selectedProductSelectionsCopy.probe2,
                        selected: val.value,
                        active: true,
                        imageData: val,
                        probe_body: false,
                      };
                    }
                  }
                }
              }
            });
          }
          break;
        case 'probe1_cable':
          selectedProductSelectionsCopy.probe1 = { ...selectedProductSelectionsCopy.probe1, probe_cable: value };
          break;
        case 'probe2_cable':
          selectedProductSelectionsCopy.probe2 = { ...selectedProductSelectionsCopy.probe2, probe_cable: value };
          break;
        case 'temp_compensation':
          if (props.tempCompProbe.length > 0) {
            props.tempCompProbe.forEach((val, i) => {
              if (value === 'remove') {
                oldSelectedOptions.probe2 = '';
                selectedProductSelectionsCopy.probe2 = {
                  ...selectedProductSelectionsCopy.probe2,
                  selected: '',
                  active: false,
                  imageData: val,
                  probe_body: false,
                };
                selectedProductSelectionsCopy.probe1 = { ...selectedProductSelectionsCopy.probe1, temperature_compensation: false };
              } else {
                if (val.value === value) {
                  if (selectedProductOptions !== undefined) {
                    oldSelectedOptions.probe2 = val;
                    selectedProductSelectionsCopy.probe2 = {
                      ...selectedProductSelectionsCopy.probe2,
                      selected: val.value,
                      active: true,
                      imageData: val,
                      probe_body: true,
                    };
                    selectedProductSelectionsCopy.probe1 = { ...selectedProductSelectionsCopy.probe1, temperature_compensation: true };
                  }
                }
              }
            });
          }
          break;
        default:
          break;
      }
      setSelectedProductOptions(oldSelectedOptions);
      setSelectedProductSelections(selectedProductSelectionsCopy);
      setStorageFromParams('product_selections', JSON.stringify(selectedProductSelectionsCopy));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProductOptions, selectedProductSelections, props.filteredProbes, props.filteredSecondaryProbes]
  );

  // If probe list changes because of measurement parameters or transmitter types, always choose the new correct first option
  React.useEffect(() => {
    if (props.filteredProbes.length > 0) {
      if (selectedProductSelections.probe1.active) {
        if (dataFromUrl !== null) {
          if (!dataFromUrl) {
            onConfiguratorSelectChange(props.filteredProbes[0].value, 'probe1');
            if (selectedProductSelections.probe2.active) {
              onConfiguratorSelectChange(props.filteredSecondaryProbes.length > 0 ? props.filteredSecondaryProbes[0].value : props.filteredProbes[0].value, 'probe2');
            }
          } else {
            onConfiguratorSelectChange(selectedProductSelections.probe1.selected, 'probe1');
            if (selectedProductSelections.probe2.active) {
              onConfiguratorSelectChange(selectedProductSelections.probe2.selected, 'probe2');
            }
            setTimeout(() => {
              setDataFromUrl(false);
            }, 500)
          }
        }
      }
    }
  }, [props.filteredProbes, props.filteredSecondaryProbes]);

  // React.useEffect(() => {
  //   if (props.filteredSecondaryProbes.length > 0) {
  //     if (selectedProductSelections.probe2.active) {
  //       onConfiguratorSelectChange(props.filteredSecondaryProbes[0].value, 'probe2');
  //     }
  //   }
  // }, [props.filteredSecondaryProbes]);

  // React.useEffect(() => {
  //   if (dataFromUrl && props.filteredProbes.length > 0) {
  //     if (selectedProductSelections.probe2.active && props.filteredSecondaryProbes.length > 0) {
  //       let filteredComponents = Object.keys(selectedProductSelections).filter((key: any) => selectedProductSelections[key].selected !== '');
  //       filteredComponents.forEach((val, i) => {
  //         onConfiguratorSelectChange(selectedProductSelections[val as keyof typeof selectedProductSelections].selected, val);
  //       });
  //       setDataFromUrl(false);
  //     } else if (!selectedProductSelections.probe2?.active) {
  //       let filteredComponents = Object.keys(selectedProductSelections).filter((key: any) => selectedProductSelections[key].selected !== '');
  //       filteredComponents.forEach((val, i) => {
  //         onConfiguratorSelectChange(selectedProductSelections[val as keyof typeof selectedProductSelections].selected, val);
  //       });
  //       setDataFromUrl(false)
  //     }

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.filteredSecondaryProbes, props.filteredProbes]);
  React.useEffect(() => {
    if (dataFromUrl) {
      let filteredComponents = Object.keys(selectedProductSelections).filter((key: any) => selectedProductSelections[key].selected !== '');
      filteredComponents.forEach((val, i) => {
        onConfiguratorSelectChange(selectedProductSelections[val as keyof typeof selectedProductSelections].selected, val);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.filteredProbes.length > 0) {
      const oldSelectedProbe1 = props.filteredProbes.find((el) => el.value === selectedProductSelections.probe1.selected);
      if (!oldSelectedProbe1) {
        onConfiguratorSelectChange(props.filteredProbes[0].value, 'probe1');
      }
      if (props.filteredSecondaryProbes.length > 0) {
        const oldSelectedProbe2 = props.filteredSecondaryProbes.find((el) => el.value === selectedProductSelections.probe2.selected);
        if (!oldSelectedProbe2) {
          onConfiguratorSelectChange(props.filteredSecondaryProbes[0].value, 'probe2');
        }
      } else {
        if (props.loadedSavedConfiguration) {
          props.onLoadedSavedConfigurationChange(false);
          // } else {
          //   // const oldSelectedProbe2 = props.filteredProbes.find((el) => el.value === selectedProductSelections.probe2.selected);
          //   // if (selectedProductSelections.probe2.selected === '' || oldSelectedProbe2) {
          //     onConfiguratorSelectChange('remove', 'probe2');
          //   // }
          // }
        } else {
          if (!selectedProductSelections.probe2.active) {
            onConfiguratorSelectChange('remove', 'probe2');
          }
          // const oldSelectedProbe2 = props.filteredProbes.find((el) => el.value === selectedProductSelections.probe2.selected);
          // if (selectedProductSelections.probe2.selected === '' || oldSelectedProbe2) {
          //   onConfiguratorSelectChange('remove', 'probe2');
          // }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filteredProbes, props.filteredSecondaryProbes]);

  const RenderSelections = () => {

    return (
      <>
        <Accordion title={t('ui.configuration_summary')} isExpanded={true}>
          <ul>
            {Object.keys(selectedProductSelections)
              .sort()
              .map((value, index) => {
                if (
                  selectedProductSelections[value as keyof typeof selectedProductSelections].active &&
                  selectedProductSelections[value as keyof typeof selectedProductSelections].imageData
                ) {
                  return (
                    <li key={index}>
                      {selectedProductSelections[value as keyof typeof selectedProductSelections].imageData.infobox[i18next.language].title},{' '}
                      {selectedProductSelections[value as keyof typeof selectedProductSelections].imageData.infobox[i18next.language].description}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        </Accordion>
        {/* <h3>{t('ui.configuration_summary')}</h3>
        <ul>
          {Object.keys(selectedProductSelections)
            .sort()
            .map((value, index) => {
              if (
                selectedProductSelections[value as keyof typeof selectedProductSelections].active &&
                selectedProductSelections[value as keyof typeof selectedProductSelections].imageData
              ) {
                return (
                  <li key={index}>
                    {selectedProductSelections[value as keyof typeof selectedProductSelections].imageData.infobox[i18next.language].title},{' '}
                    {selectedProductSelections[value as keyof typeof selectedProductSelections].imageData.infobox[i18next.language].description}
                  </li>
                );
              } else {
                return null;
              }
            })}
        </ul> */}
      </>
    );
  };

  return (
    <div className={`configurator-content ${isMobileOnly ? 'container--mobile' : 'container--desktop'}`}>
      {props.showConfiguration && props.filteredTransmitters.length > 0 ? (
        <>
          <div className="rendered-selections">
            <RenderSelections />
          </div>
          <Flex flexDirection={'row'}>
            <Flex.Item flexGrow={1}>
              <Heading level={2}>2. {t('ui.configuration.heading')}</Heading>
              <p className="vai-margin-left-none">{t('ui.configuration.text')}</p>
              <ConfiguratorCanvas
                filteredProbes={props.filteredProbes}
                filteredSecondaryProbes={props.filteredSecondaryProbes}
                tempCompProbe={props.tempCompProbe}
                selectedProductOptions={selectedProductOptions}
                probeAttachments={props.probeAttachments}
                selectedTransmitter={selectedTransmitter}
                selectedProductSelections={selectedProductSelections}
                selectedTransmitterOptions={selectedTransmitterOptions}
                transmitterType={props.transmitterType}
                showOverlay={showOverlay}
                hoveredCable={hoveredCable}
              />
            </Flex.Item>
            <Flex.Item flexBasis={'35%'}>
            <div className="rendered-selections mobile">
                <RenderSelections />
              </div>
              <ConfiguratorSelects
                filteredProbes={props.filteredProbes}
                filteredSecondaryProbes={props.filteredSecondaryProbes}
                tempCompProbe={props.tempCompProbe}
                filteredTransmitters={props.filteredTransmitters}
                selectedMeasurements={props.selectedMeasurements}
                onConfiguratorSelectChange={onConfiguratorSelectChange}
                selectedProductOptions={selectedProductOptions}
                selectedTransmitter={selectedTransmitter}
                onTransmitterChange={setSelectedTransmitter}
                selectedProductSelections={selectedProductSelections}
                onSelectedTransmitterOptionsChange={onSelectedTransmitterOptionsChange}
                selectedTransmitterOptions={selectedTransmitterOptions}
                transmitterType={props.transmitterType}
                openModal={props.openModal}
                onHoveredCableChange={onHoveredCableChange}
              />
            </Flex.Item>
          </Flex>
        </>
      ) : (
        <ConfiguratorPlaceholder isConfigurationReady={props.isConfigurationReady} filteredProbes={props.filteredProbes} />
      )}
    </div>
  );
};

export default ConfiguratorContent;
